import gql from "graphql-tag";

export const providerAlertsByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderAlertsByToken(
		$filter: FilterFindManyProviderAlertInput
		$sort: JSON
		$limit: Int
		$skip: Int
	) {
		providerAlertsByTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...providerAlertsByTokenQueryFragment
		}
	}
`;

export const removeProviderAlertByTokenQuery = (fragment) => gql`
	${fragment}
	query RemoveProviderAlertsByToken(
		$filter: FilterRemoveOneProviderActionInput!
		$limit: Int
	) {
		removeProviderAlertsByTokenV2(filter: $filter, limit: $limit) {
			...removeProviderAlertByTokenQueryFragment
		}
	}
`;

export const updateProviderAlertByTokenQuery = (fragment) => gql`
	${fragment}
	query UpdateProviderAlertByToken(
		$filter: FilterFindOneProviderAlertInput
		$sort: JSON
		$limit: Int
		$skip: Int
	) {
		updateProviderAlertByTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...updateProviderAlertByTokenQueryFragment
		}
	}
`;

export const updateProviderAlertsByTokenQuery = (fragment) => gql`
	${fragment}
	query UpdateProviderAlertsByToken(
		$filter: FilterFindManyProviderAlertInput
		$sort: JSON
		$limit: Int
		$skip: Int
	) {
		updateProviderAlertsByTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...updateProviderAlertsByTokenQueryFragment
		}
	}
`;
