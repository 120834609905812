import gql from "graphql-tag";

export const requestsQuery = (fragment) => gql`
	${fragment}
	query Requests(
		$filter: FilterFindManyRequestInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		requestsV2(filter: $filter, sort: $sort, limit: $limit, skip: $skip) {
			...requestsQueryFragment
		}
	}
`;

export const countRequestsQuery = () => gql`
	query CountRequests($filter: FilterCountRequestInput) {
		countRequestsV2(filter: $filter)
	}
`;
