import MainService from "../Main";
import * as operations from "./Tag.graphql";

class TagService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getTags(variables = {}, options = {}) {
		return this.query(variables, options, "tags");
	}

	updateTag(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateTag");
	}

	createTag(variables = {}, options = {}) {
		return this.mutation(variables, options, "createTag");
	}
}

export default TagService;
