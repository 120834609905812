import MainService from "../Main";
import * as operations from "./Category.graphql.js";

class CategoryService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations, "Category");
	}

	getCategory(variables = {}, options = {}) {
		return this.query(variables, options, "category");
	}

	getCategories(variables = {}, options = {}) {
		return this.query(variables, options, "categories");
	}

	countCategories(variables = {}, options = {}) {
		return this.query(variables, options, "countCategories");
	}

	paginateCategories(variables = {}, options = {}) {
		return this.query(variables, options, "paginateCategories");
	}

	createCategory(variables = {}, options = {}) {
		return this.mutation(variables, options, "createCategory");
	}

	updateCategory(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateCategory");
	}

	/*****/

	isCategory(variables = {}, options = {}) {
		return this.query(variables, options, "isCategory");
	}

	getCategoryByName(variables = {}, options = {}) {
		return this.query(variables, options, "categoryByName");
	}
}

export default CategoryService;
