import gql from "graphql-tag";

export const clientNotificationByTokenQuery = (fragment) => gql`
	${fragment}
	query ClientNotificationByToken {
		clientNotificationByToken {
			...clientNotificationByTokenQueryFragment
		}
	}
`;

export const updateClientNotificationByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateClientNotificationByToken(
		$input: UpdateClientNotificationInput
	) {
		updateClientNotificationByToken(input: $input) {
			...updateClientNotificationByTokenMutationFragment
		}
	}
`;
