import MainService from "../Main";
import * as operations from "./City.graphql";

class CityService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	isCity(variables = {}, options = {}) {
		return this.query(variables, options, "isCity");
	}

	getCity(variables = {}, options = {}) {
		return this.query(variables, options, "city");
	}

	getCities(variables = {}, options = {}) {
		return this.query(variables, options, "cities");
	}

	paginateCities(variables = {}, options = {}) {
		return this.query(variables, options, "paginateCities");
	}

	updateCity(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateCity");
	}
}

export default CityService;
