import MainService from "../Main";
import * as operations from "./StripePortalConfiguration.graphql";

class StripePortalConfigurationService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default StripePortalConfigurationService;
