import MainService from "../Main";
import * as operations from "./CreditPackage.graphql";

class CreditPackage extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}

	purchaseCreditPackage(variables = {}, options = {}) {
		return this.mutation(variables, options, "purchaseCreditPackage");
	}
}

export default CreditPackage;
