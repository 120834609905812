import MainService from "../Main";
import * as operations from "./Login.graphql";

class LoginService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	loginWithFb(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/loginWithFb" },
			variables,
			options,
			"loginWithFb"
		);
	}

	loginWithGoogle(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/loginWithGoogle" },
			variables,
			options,
			"loginWithGoogle"
		);
	}

	login(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/login" },
			variables,
			options,
			"login"
		);
	}

	logout(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/login" },
			variables,
			options,
			"logout"
		);
	}

	invite(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/invite" },
			variables,
			options,
			"invite"
		);
	}

	resetPassword(variables = {}, options = {}) {
		return this.mutation(variables, options, "resetPassword");
	}
}

export default LoginService;
