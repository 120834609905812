import gql from "graphql-tag";

export const questionsQuery = (fragment) => {
	const answersQueryFragmentDef = fragment.definitions.find(
		(def) => def.name?.value === "answersQueryFragment"
	);
	return answersQueryFragmentDef
		? gql`
				${fragment}
				query Questions(
					$filter: FilterFindManyQuestionInput
					$sort: JSON
					$limit: Int
					$skip: Int
					$filterAnswers: FilterFindManyAnswerInput
					$sortAnswers: JSON
					$limitAnswers: Int
					$skipAnswers: Int
				) {
					questionsV2(
						filter: $filter
						sort: $sort
						limit: $limit
						skip: $skip
					) {
						...questionsQueryFragment
						answers(
							filter: $filterAnswers
							sort: $sortAnswers
							limit: $limitAnswers
							skip: $skipAnswers
						) {
							...answersQueryFragment
						}
					}
				}
		  `
		: gql`
				${fragment}
				query Questions(
					$filter: FilterFindManyQuestionInput
					$sort: JSON
					$limit: Int
					$skip: Int
				) {
					questionsV2(
						filter: $filter
						sort: $sort
						limit: $limit
						skip: $skip
					) {
						...questionsQueryFragment
					}
				}
		  `;
};

export const createQuestionMutation = (fragment) => gql`
	${fragment}
	mutation CreateQuestion($record: CreateOneQuestionInput!) {
		createQuestionV2(record: $record) {
			...createQuestionMutationFragment
		}
	}
`;

export const updateQuestionMutation = (fragment) => gql`
	${fragment}
	mutation UpdateQuestion(
		$record: UpdateOneQuestionInput!
		$filter: FilterUpdateOneQuestionInput
		$skip: Int
		$sort: JSON
	) {
		updateQuestionV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateQuestionMutationFragment
		}
	}
`;

export const removeQuestionsMutation = (fragment) => gql`
	${fragment}
	mutation RemoveQuestions(
		$filter: FilterRemoveManyQuestionInput!
		$limit: Int
	) {
		removeQuestionsV2(filter: $filter, limit: $limit) {
			...removeQuestionsMutationFragment
		}
	}
`;

export const cloneQuestionByCategoryMutation = (fragment) => gql`
	${fragment}
	mutation CloneQuestionByCategory($category: ID!, $question: ID!) {
		cloneQuestionByCategory(category: $category, question: $question) {
			...cloneQuestionByCategoryMutationFragment
		}
	}
`;

export const cloneQuestionByServiceMutation = (fragment) => gql`
	${fragment}
	mutation CloneQuestionByService($service: ID!, $question: ID!) {
		cloneQuestionByService(service: $service, question: $question) {
			...cloneQuestionByServiceMutationFragment
		}
	}
`;
