import gql from "graphql-tag";

export const createProviderFunnelStepConfigurationMutation = (fragment) => gql`
	${fragment}
	mutation CreateProviderFunnelStepConfiguration(
		$record: CreateOneProviderFunnelStepConfigurationInput!
	) {
		createProviderFunnelStepConfigurationV2(record: $record) {
			...createProviderFunnelStepConfigurationMutationFragment
		}
	}
`;

export const updateProviderFunnelStepConfigurationMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderFunnelStepConfiguration(
		$record: UpdateOneProviderFunnelStepConfigurationInput!
		$filter: FilterUpdateOneProviderFunnelStepConfigurationInput
		$skip: Int
		$sort: JSON
	) {
		updateProviderFunnelStepConfigurationV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateProviderFunnelStepConfigurationMutationFragment
		}
	}
`;

export const removeProviderFunnelStepConfigurationMutation = (fragment) => gql`
	${fragment}
	mutation RemoveProviderFunnelStepConfiguration(
		$filter: FilterRemoveOneProviderFunnelStepConfigurationInput
		$sort: JSON
	) {
		removeProviderFunnelStepConfigurationV2(filter: $filter, sort: $sort) {
			...removeProviderFunnelStepConfigurationMutationFragment
		}
	}
`;
export const providerFunnelStepConfigurationsQuery = (fragment) => gql`
	${fragment}
	query ProvidersFunnelsStepsConfigurations(
		$filter: FilterFindManyProviderFunnelStepConfigurationInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		providersFunnelsStepsConfigurationsV2(
			filter: $filter
			skip: $skip
			limit: $limit
			sort: $sort
		) {
			...providerFunnelStepConfigurationsQueryFragment
		}
	}
`;

export const paginateProvidersFunnelsStepsConfigurationsQuery = (
	fragment
) => gql`
	${fragment}
	query PaginateProvidersFunnelsStepsConfigurations(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyProviderFunnelStepConfigurationInput
		$sort: JSON
	) {
		paginateProvidersFunnelsStepsConfigurationsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateProvidersFunnelsStepsConfigurationsQueryFragment
		}
	}
`;
