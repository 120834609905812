import MainService from "../Main";
import * as operations from "./ProviderMedia.graphql";

class ProvidersMediaService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getProvidersMedia(variables = {}, options = {}) {
		return this.query(variables, options, "providersMedia");
	}

	approveProviderMedia(variables = {}, options = {}) {
		return this.mutation(variables, options, "approveProviderMedia");
	}

	addProviderMediaVideo(variables = {}, options = {}) {
		return this.mutation(variables, options, "addProviderMediaVideo");
	}

	addProviderMediaPictures(variables = {}, options = {}) {
		return this.mutation(variables, options, "addProviderMediaPictures");
	}

	moveProviderMediaByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "moveProviderMediaByKey");
	}

	rotateProviderMediaPictureByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "rotateProviderMediaPictureByKey");
	}

	updateProviderMediaApproval(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderMediaApproval");
	}

	updateProviderMediaCaption(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderMediaCaption");
	}

	updateProviderMediaPictureHash(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderMediaPictureHash");
	}

	removeProviderMediaPictureByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderMediaPictureByKey");
	}

	removeProviderMediaVideoByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderMediaVideoByKey");
	}
}

export default ProvidersMediaService;
