import gql from "graphql-tag";

export const providerSavedRepliesByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderSavedRepliesByToken(
		$filter: FilterFindManyProviderSavedReplyInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		providerSavedRepliesByTokenV2(
			filter: $filter
			skip: $skip
			limit: $limit
			sort: $sort
		) {
			...providerSavedRepliesByTokenQueryFragment
		}
	}
`;

export const updateProviderSavedReplyByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderSavedReplyByToken(
		$record: UpdateOneProviderSavedReplyInput!
		$filter: FilterUpdateOneProviderSavedReplyInput
		$skip: Int
		$sort: JSON
	) {
		updateProviderSavedReplyByTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateProviderSavedReplyByTokenMutationFragment
		}
	}
`;

export const createProviderSavedReplyByTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateProviderSavedReplyByToken(
		$record: CreateOneProviderSavedReplyInput!
	) {
		createProviderSavedReplyByTokenV2(record: $record) {
			...createProviderSavedReplyByTokenMutationFragment
		}
	}
`;

export const removeProviderSavedReplyByTokenMutation = (fragment) => gql`
	${fragment}
	mutation RemoveProviderSavedReplyByToken(
		$filter: FilterRemoveOneProviderSavedReplyInput
		$sort: JSON
	) {
		removeProviderSavedReplyByTokenV2(filter: $filter, sort: $sort) {
			...removeProviderSavedReplyByTokenMutationFragment
		}
	}
`;
