import gql from "graphql-tag";

export const providerFunnelCurrentStepQuery = (fragment) => gql`
	${fragment}
	query ProviderFunnelCurrentStep(
		$currentStepId: String
		$isOnboarding: Boolean
		$isServiceSetup: Boolean
		$isBoost: Boolean
		$serviceKey: ID!
	) {
		providerFunnelCurrentStep(
			currentStepId: $currentStepId
			isOnboarding: $isOnboarding
			isServiceSetup: $isServiceSetup
			isBoost: $isBoost
			serviceKey: $serviceKey
		) {
			...providerFunnelCurrentStepQueryFragment
		}
	}
`;

export const completeProviderFunnelStepMutation = (fragment) => gql`
	${fragment}
	mutation CompleteProviderFunnelStep(
		$serviceKey: ID!
		$stepId: ID!
		$isOnboarding: Boolean
		$isServiceSetup: Boolean
		$isBoost: Boolean
	) {
		completeProviderFunnelStep(
			serviceKey: $serviceKey
			stepId: $stepId
			isOnboarding: $isOnboarding
			isServiceSetup: $isServiceSetup
			isBoost: $isBoost
		) {
			...completeProviderFunnelStepMutationFragment
		}
	}
`;
