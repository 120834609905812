import MainService from "../Main";
import * as operations from "./Message.graphql";

class MessageService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getMessagesByClientToken(variables = {}, options = {}) {
		return this.query(variables, options, "messagesByClientToken");
	}

	countMessages(variables = {}, options = {}) {
		return this.query(variables, options, "countMessages");
	}

	createMessageByProviderToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createMessageByProviderToken");
	}

	createMessageByClientToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createMessageByClientToken");
	}

	updateMessagesByProviderToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateMessagesByProviderToken");
	}

	updateMessagesByClientToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateMessagesByClientToken");
	}
}

export default MessageService;
