import MainService from "../Main";
import * as operations from "./Provider.graphql";

class ProviderService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	providerByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerByToken");
	}

	getProvider(variables = {}, options = {}) {
		return this.query(variables, options, "provider");
	}

	getProviderProfileImages(variables = {}, options = {}) {
		return this.query(variables, options, "getProviderProfileImages");
	}

	getProviderByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerByToken");
	}

	getProviderByKey(variables = {}, options = {}) {
		return this.query(variables, options, "providerByKey");
	}

	updateProvider(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProvider");
	}

	updateProviderByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderByToken");
	}

	updateProviderPrivileged(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderPrivileged");
	}

	updateProviderBusinessHours(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderBusinessHours");
	}

	updateProviderBusinessHoursByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderBusinessHoursByToken"
		);
	}

	updateProviderCreditCard(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderCreditCard");
	}

	updateProviderServiceConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderServiceConfiguration"
		);
	}

	completeProviderFunnelStep(variables = {}, options = {}) {
		return this.mutation(variables, options, "completeProviderFunnelStep");
	}

	providerFunnelNextStep(variables = {}, options = {}) {
		return this.rest(
			{ url: "/pros/funnel/nextStep", method: "get" },
			variables,
			options,
			"providerFunnelNextStep"
		);
	}

	removeProviderMediaVideoByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderMediaVideoByKey");
	}

	removeProviderMediaPictureByKey(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderMediaPictureByKey");
	}

	updateProviderNotifications(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderNotifications");
	}

	updateProviderNotificationByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderNotificationByToken"
		);
	}

	createProviderCreditCard(variables = {}, options = {}) {
		return this.mutation(variables, options, "createProviderCreditCard");
	}

	deleteProviderCreditCard(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteProviderCreditCard");
	}

	toggleHideProvider(variables = {}, options = {}) {
		return this.mutation(variables, options, "toggleHideProvider");
	}

	searchProviders(variables = {}, options = {}) {
		return this.query(variables, options, "searchProviders");
	}

	getProviderPrivileged(variables = {}, options = {}) {
		return this.query(variables, options, "providerPrivileged");
	}

	toggleBanProvider(variables = {}, options = {}) {
		return this.mutation(variables, options, "toggleBanProvider");
	}

	getNotifications(variables = {}, options = {}) {
		return this.query(variables, options, "notifications");
	}

	updateNotifications(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateNotification");
	}

	updateNotificationViewed(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateNotificationViewed");
	}

	paginateProviders(variables = {}, options = {}) {
		return this.query(variables, options, "paginateProviders");
	}
}

export default ProviderService;
