import gql from "graphql-tag";

export const answersQuery = (fragment) => gql`
	${fragment}
	query Answers(
		$sort: JSON
		$filter: FilterFindManyAnswerInput
		$limit: Int
		$skip: Int
	) {
		answersV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...answersQueryFragment
		}
	}
`;

export const updateAnswerMutation = (fragment) => gql`
	${fragment}
	mutation UpdateAnswer(
		$record: UpdateOneAnswerInput!
		$filter: FilterUpdateOneAnswerInput
		$skip: Int
		$sort: JSON
	) {
		updateAnswerV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateAnswerMutationFragment
		}
	}
`;

export const createAnswerMutation = (fragment) => gql`
	${fragment}
	mutation CreateAnswer($record: CreateOneAnswerInput!) {
		createAnswerV2(record: $record) {
			...createAnswerMutationFragment
		}
	}
`;

export const removeAnswerMutation = (fragment) => gql`
	${fragment}
	mutation RemoveAnswer($filter: FilterRemoveOneAnswerInput, $sort: JSON) {
		removeAnswerV2(filter: $filter, sort: $sort) {
			...removeAnswerMutationFragment
		}
	}
`;
