import MainService from "../Main";
import * as operations from "./Search.graphql.js";

class SearchService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	search(variables = {}, options = {}) {
		return this.query(variables, options, "search");
	}

	searchByLead(variables = {}, options = {}) {
		return this.query(variables, options, "searchByLead");
	}

	searchByCityServiceStat(variables = {}, options = {}) {
		return this.query(variables, options, "searchByCityServiceStat");
	}

	searchByProfile(variables = {}, options = {}) {
		return this.query(variables, options, "searchByProfile");
	}

	searchByProfileUri(variables = {}, options = {}) {
		return this.query(variables, options, "searchByProfileUri");
	}

	searchByNearMeUri(variables = {}, options = {}) {
		return this.query(variables, options, "searchByNearMeUri");
	}

	searchByComboUri(variables = {}, options = {}) {
		return this.query(variables, options, "searchByComboUri");
	}

	searchByCityUri(variables = {}, options = {}) {
		return this.query(variables, options, "searchByCityUri");
	}

	searchByContentPageUri(variables = {}, options = {}) {
		return this.query(
			variables,
			{ keepTypeName: true, ...options },
			"searchByContentPageUri"
		);
	}

	searchByStateUri(variables = {}, options = {}) {
		return this.query(variables, options, "searchByStateUri");
	}

	searchForMatches(variables = {}, options = {}) {
		return this.query(variables, options, "searchForMatches");
	}

	searchServiceAutoComplete(variables = {}, options = {}) {
		return this.query(variables, options, "searchServiceAutoComplete");
	}

	searchByMap(variables = {}, options = {}) {
		return this.query(variables, options, "searchByMap");
	}
}

export default SearchService;
