class RootService {
	constructor({ queries, mutations, validations } = {}) {
		const req = require.context("./", true, /index\.(js)$/);
		const paths = req.keys().filter((path) => path.split("/").length > 2);
		const Services = paths.map((path) => req(path).default).filter(Boolean);
		for (const Service of Services) {
			const service = new Service({ queries, mutations, validations });
			Object.assign(this, { ...service });
		}
	}
}

export default RootService;
