import MainService from "../Main";
import required from "@libs/required";
import * as operations from "./Image.graphql";

class ImageService extends MainService {
	constructor(...args) {
		super(...args);
		this.patch(operations);
	}

	async uploadImageViaCropper(
		cropper = required`cropper`,
		{
			fileName = required`fileName`,
			userType = required`userType`,
			imageType = required`imageType`,
		} = {}
	) {
		try {
			const ImageUpload = await import("@src/libs/ImageUpload");
			const imageUpload = new ImageUpload.default();

			const blob = await imageUpload.getBlobFromCropper(cropper);
			const file = new File([blob], `${fileName}`);

			return this.uploadFile(file, { userType, imageType });
		} catch (err) {
			throw err;
		}
	}

	uploadImageViaFile(
		file = required`file`,
		{ userType = required`userType`, imageType = required`imageType` } = {}
	) {
		return this.uploadFile(file, { userType, imageType });
	}

	// uploadImageViaFile(
	// 	file = required`file`,
	// 	{
	// 		userType = required`userType`,
	// 		imageType = required`imageType`,
	// 		tags,
	// 		metadata,
	// 		eager = required`eager`,
	// 	} = {},
	// 	options = {}
	// ) {
	// 	return this.uploadImageV2(
	// 		file,
	// 		{ userType, imageType, tags, metadata, eager },
	// 		options
	// 	);
	// }

	async uploadFile(file = required`file`, options = {}) {
		try {
			const ImageUpload = await import("@src/libs/ImageUpload");
			const imageUpload = new ImageUpload.default();

			const response = await imageUpload.uploadFile(file, options);
			return { ...response, originalFileName: file.name };
		} catch (err) {
			throw err;
		}
	}

	async uploadImageV2(
		file = required`file`,
		{
			userType = required`userType`,
			imageType = required`imageType`,
			tags,
			metadata,
			eager = required`eager`,
		} = {}
	) {
		try {
			const ImageUpload = await import("@src/libs/ImageUpload");
			const imageUpload = new ImageUpload.default();

			const response = await imageUpload.uploadFileV2(file, {
				userType,
				imageType,
				tags,
				metadata,
				eager,
			});
			return response;
		} catch (err) {
			throw err;
		}
	}

	getImageUploads(variables = {}, options = {}) {
		return this.query(variables, options, "imageUploads");
	}

	approveImageUpload(variables = {}, options = {}) {
		return this.mutation(variables, options, "approveImageUpload");
	}
}

export default ImageService;
