import gql from "graphql-tag";

export const defaultStripePortalConfigurationQuery = (fragment) => gql`
	query DefaultStripePortalConfiguration {
		defaultStripePortalConfiguration
	}
`;

export const updateDefaultStripePortalConfigurationMutation = (fragment) => gql`
	mutation UpdateDefaultStripePortalConfiguration($data: JSON!) {
		updateDefaultStripePortalConfiguration(data: $data)
	}
`;
