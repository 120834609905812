import gql from "graphql-tag";

export const createReviewCommentByProviderTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateReviewCommentByProviderToken(
		$record: CreateOneReviewCommentInput!
	) {
		createReviewCommentByProviderTokenV2(record: $record) {
			...createReviewCommentByProviderTokenMutationFragment
		}
	}
`;

export const createReviewCommentByClientTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateReviewCommentByClientToken(
		$record: CreateOneReviewCommentInput!
	) {
		createReviewCommentByClientTokenV2(record: $record) {
			...createReviewCommentByClientTokenMutationFragment
		}
	}
`;
