import gql from "graphql-tag";

export const quotesQuery = (fragment) => gql`
	${fragment}
	query Quotes(
		$filter: FilterFindManyQuoteInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		quotesV2(filter: $filter, sort: $sort, limit: $limit, skip: $skip) {
			...quotesQueryFragment
		}
	}
`;

export const searchQuotesQuery = (fragment) => gql`
	${fragment}
	query SearchQuotes(
		$filterType: SearchQuotesFilterTypeEnum
		$clientId: ID
		$limit: Int
		$skip: Int
	) {
		searchQuotesV2(
			filterType: $filterType
			clientId: $clientId
			limit: $limit
			skip: $skip
		) {
			...searchQuotesQueryFragment
		}
	}
`;

export const quotesByProviderTokenQuery = (fragment) => gql`
	${fragment}
	query QuotesByProviderToken(
		$filter: FilterFindManyQuoteInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		quotesByProviderTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...quotesByProviderTokenQueryFragment
		}
	}
`;

export const paginateQuotesByProviderTokenQuery = (fragment) => gql`
	${fragment}
	query PaginateQuotesByProviderToken(
		$filter: FilterFindManyQuoteInput
		$page: Int
		$perPage: Int
		$sort: JSON
	) {
		paginateQuotesByProviderTokenV2(
			filter: $filter
			sort: $sort
			page: $page
			perPage: $perPage
		) {
			...paginateQuotesByProviderTokenQueryFragment
		}
	}
`;

export const paginateQuotesQuery = (fragment) => gql`
	${fragment}
	query PaginateQuotes(
		$filter: FilterFindManyQuoteInput
		$page: Int
		$perPage: Int
		$sort: JSON
	) {
		paginateQuotesV2(
			filter: $filter
			sort: $sort
			page: $page
			perPage: $perPage
		) {
			...paginateQuotesQueryFragment
		}
	}
`;

export const countQuotesQuery = () => gql`
	query CountQuotes($filter: FilterCountQuoteInput) {
		countQuotesV2(filter: $filter)
	}
`;

export const countQuotesByProviderTokenQuery = () => gql`
	query CountQuotesByProviderToken($filter: FilterCountQuoteInput) {
		countQuotesByProviderTokenV2(filter: $filter)
	}
`;

export const quoteByProviderTokenQuery = (fragment) => gql`
	${fragment}
	query QuoteByProviderToken(
		$filter: FilterFindOneQuoteInput
		$skip: Int
		$sort: JSON
	) {
		quoteByProviderTokenV2(filter: $filter, skip: $skip, sort: $sort) {
			...quoteByProviderTokenQueryFragment
		}
	}
`;

export const quoteByClientTokenQuery = (fragment) => gql`
	${fragment}
	query QuoteByClientToken(
		$filter: FilterFindOneQuoteInput
		$skip: Int
		$sort: JSON
	) {
		quoteByClientTokenV2(filter: $filter, skip: $skip, sort: $sort) {
			...quoteByClientTokenQueryFragment
		}
	}
`;

export const updateQuoteMutation = (fragment) => gql`
	${fragment}
	mutation UpdateQuote(
		$record: UpdateOneQuoteInput!
		$filter: FilterUpdateOneQuoteInput
		$skip: Int
		$sort: JSON
	) {
		updateQuoteV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateQuoteMutationFragment
		}
	}
`;

export const updateQuoteByProviderTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateQuoteByProviderToken(
		$record: UpdateOneQuoteInput!
		$filter: FilterUpdateOneQuoteInput
		$skip: Int
		$sort: JSON
	) {
		updateQuoteByProviderTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateQuoteByProviderTokenMutationFragment
		}
	}
`;

export const updateQuoteByClientTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateQuoteByClientToken(
		$record: UpdateOneQuoteInput!
		$filter: FilterUpdateOneQuoteInput
		$skip: Int
		$sort: JSON
	) {
		updateQuoteByClientTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateQuoteByClientTokenMutationFragment
		}
	}
`;

export const acceptQuoteMutation = (fragment) => gql`
	${fragment}
	mutation AcceptQuote($key: ID!) {
		acceptQuoteV2(key: $key) {
			...acceptQuoteMutationFragment
		}
	}
`;

export const declineQuoteMutation = (fragment) => gql`
	${fragment}
	mutation DeclineQuote($key: ID!) {
		declineQuoteV2(key: $key) {
			...declineQuoteMutationFragment
		}
	}
`;

export const sendQuoteMutation = (fragment) => gql`
	${fragment}
	mutation SendQuote($input: SendQuoteInput!) {
		sendQuoteV2(input: $input) {
			...sendQuoteMutationFragment
		}
	}
`;
