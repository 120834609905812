import MainService from "../Main";
import * as operations from "./FunnelStepConfiguration.graphql";

class ProviderFunnelStepConfigurationService extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}

	createProviderFunnelStepConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createProviderFunnelStepConfiguration"
		);
	}

	updateProviderFunnelStepConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderFunnelStepConfiguration"
		);
	}

	moveFunnelStepConfigurationUp(variables = {}, options = {}) {
		return this.mutation(variables, options, "moveFunnelStepConfigurationUp");
	}

	moveFunnelStepConfigurationDown(variables = {}, options = {}) {
		return this.mutation(variables, options, "moveFunnelStepConfigurationDown");
	}

	deleteFunnelStepConfiguration(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteFunnelStepConfiguration");
	}

	removeProviderFunnelStepConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"removeProviderFunnelStepConfiguration"
		);
	}

	deleteFunnelStepConfigurationTag(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"deleteFunnelStepConfigurationTag"
		);
	}

	providerFunnelStepConfigurations(variables = {}, options = {}) {
		return this.query(variables, options, "providerFunnelStepConfigurations");
	}

	paginateProvidersFunnelsStepsConfigurations(variables = {}, options = {}) {
		return this.query(
			variables,
			options,
			"paginateProvidersFunnelsStepsConfigurations"
		);
	}
}

export default ProviderFunnelStepConfigurationService;
