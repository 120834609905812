import MainService from "../Main";
import * as operations from "./Account.graphql";

class AccountService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getAccountByToken(variables = {}, options = {}) {
		return this.query(variables, options, "accountByToken");
	}

	updateAccount(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateAccount");
	}

	updateAccountByProviderToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateAccountByProviderToken");
	}

	updatePassword(variables = {}, options = {}) {
		return this.mutation(variables, options, "updatePassword");
	}

	checkAccountStatus(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/check/account/status" },
			variables,
			options,
			"checkAccountStatus"
		);
	}

	checkAccountStatusByProviderId(variables = {}, options = {}) {
		return this.query(variables, options, "checkAccountStatusByProviderId");
	}

	updateAccountPrivileged(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateAccountPrivileged");
	}

	paginateAccounts(variables = {}, options = {}) {
		return this.query(variables, options, "paginateAccounts");
	}
}

export default AccountService;
