import gql from "graphql-tag";

export const clientByTokenQuery = (fragment) => gql`
	${fragment}
	query ClientByToken(
		$filter: FilterFindOneClientInput
		$skip: Int
		$sort: JSON
	) {
		clientByTokenV2(filter: $filter, skip: $skip, sort: $sort) {
			...clientByTokenQueryFragment
		}
	}
`;

export const updateClientMutation = (fragment) => gql`
	${fragment}
	mutation UpdateClientByToken(
		$record: UpdateOneClientInput!
		$filter: FilterUpdateOneClientInput
		$skip: Int
		$sort: JSON
	) {
		updateClientByTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateClientMutationFragment
		}
	}
`;

export const updateClientByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateClientByToken(
		$record: UpdateOneClientInput!
		$filter: FilterUpdateOneClientInput
		$skip: Int
		$sort: JSON
	) {
		updateClientByTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateClientByTokenMutationFragment
		}
	}
`;

export const removeClientByTokenMutation = (fragment) => gql`
	${fragment}
	mutation RemoveClientByToken(
		$filter: FilterRemoveOneClientInput
		$sort: JSON
	) {
		removeClientByTokenV2(filter: $filter, sort: $sort) {
			...removeClientByTokenMutationFragment
		}
	}
`;

export const paginateClientsQuery = (fragment) => gql`
	${fragment}
	query PaginateClients(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyClientInput
		$sort: JSON
	) {
		paginateClientsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateClientsQueryFragment
		}
	}
`;
