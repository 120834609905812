import gql from "graphql-tag";

export const serviceBusinessModelGroupsQuery = (fragment) => gql`
	${fragment}
	query ServiceBusinessModelGroups(
		$limit: Int
		$skip: Int
		$filter: FilterFindManyServiceBusinessModelGroupInput
		$sort: JSON
	) {
		serviceBusinessModelGroupsV2(
			limit: $limit
			skip: $skip
			filter: $filter
			sort: $sort
		) {
			...serviceBusinessModelGroupsQueryFragment
		}
	}
`;

export const paginateServiceBusinessModelGroupsQuery = (fragment) => gql`
	${fragment}
	query PaginateServiceBusinessModelGroups(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyServiceBusinessModelGroupInput
		$sort: JSON
	) {
		paginateServiceBusinessModelGroupsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateServiceBusinessModelGroupsQueryFragment
		}
	}
`;

export const updateServiceBusinessModelGroupMutation = (fragment) => gql`
	${fragment}
	mutation UpdateServiceBusinessModelGroup(
		$record: UpdateOneServiceBusinessModelGroupInput!
		$filter: FilterUpdateOneServiceBusinessModelGroupInput
		$skip: Int
		$sort: JSON
	) {
		updateServiceBusinessModelGroupV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateServiceBusinessModelGroupMutationFragment
		}
	}
`;

export const createServiceBusinessModelGroupMutation = (fragment) => gql`
	${fragment}
	mutation CreateServiceBusinessModelGroup(
		$record: CreateOneServiceBusinessModelGroupInput!
	) {
		createServiceBusinessModelGroupV2(record: $record) {
			...createServiceBusinessModelGroupMutationFragment
		}
	}
`;
