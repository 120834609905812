import gql from "graphql-tag";

export const geoLocationByZipQuery = (fragment) => gql
	`${fragment}
	query GeoLocationByZip($zip: String!) {
		geoLocationByZip(zip: $zip) {
			...geoLocationByZipQueryFragment
		}
	}
`;

export const geoLocationByZipAndAddressQuery = (fragment) => gql
`${fragment}
	query GeoLocationByZipAndAddress($zip: String!, $address: String!) {
		geoLocationByZipAndAddress(zip: $zip, address: $address) {
			...geoLocationByZipAndAddressQueryFragment
		}
	}
`;

export const geoipQuery = (fragment) => gql`
	${fragment}
	query Geoip($ip: String!) {
		geoip(ip: $ip) {
			...geoipQueryFragment
		}
	}
`;

export const geoBoundaryByProviderQuery = () => gql`
	${fragment}
	query geoBoundaryByProvider($distance: Int) {
		geoBoundaryByProvider(distance: $distance) {
			...geoBoundaryByProviderQueryFragment
		}
	}
`;
