import MainService from "../Main";
import * as operations from "./Request.graphql";

class RequestService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getRequests(variables = {}, options = {}) {
		return this.query(variables, options, "requests");
	}

	countRequests(variables = {}, options = {}) {
		return this.query(variables, options, "countRequests");
	}

	updateRequest(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateRequest");
	}

	requestsByProvider(variables = {}, options = {}) {
		return this.query(variables, options, "requestsByProvider");
	}

	requestByKey(variables = {}, options = {}) {
		return this.query(variables, options, "requestByKey");
	}

	toggleActive(variables = {}, options = {}) {
		return this.mutation(variables, options, "toggleRequestActive");
	}

	setRequestViewed(variables = {}, options = {}) {
		return this.mutation(variables, options, "setRequestViewed");
	}
}

export default RequestService;
