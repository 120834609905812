import gql from "graphql-tag";

export const tagsQuery = (fragment) => gql`
	${fragment}
	query Tags(
		$sort: JSON
		$filter: FilterFindManyTagInput
		$limit: Int
		$skip: Int
	) {
		tagsV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...tagsQueryFragment
		}
	}
`;

export const createTagMutation = (fragment) => gql`
	${fragment}
	mutation CreateTag($record: CreateOneTagInput!) {
		createTagV2(record: $record) {
			...createTagMutationFragment
		}
	}
`;
