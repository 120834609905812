import MainService from "../Main";
import * as operations from "./CityServiceStat.graphql";

class CityServiceStatService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	isCityServiceStat(variables = {}, options = {}) {
		return this.query(variables, options, "isCityServiceStat");
	}

	getCityServiceStat(variables = {}, options = {}) {
		return this.query(variables, options, "cityServiceStat");
	}

	getProviderCityServiceStats(variables = {}, options = {}) {
		return this.query(variables, options, "providerCityServiceStats");
	}

	getPriorityCityServiceStats(variables = {}, options = {}) {
		return this.query(variables, options, "priorityCityServiceStats");
	}

	getCityServiceStats(variables = {}, options = {}) {
		return this.query(variables, options, "cityServiceStats");
	}

	paginateCitiesServicesStats(variables = {}, options = {}) {
		return this.query(variables, options, "paginateCitiesServicesStats");
	}

	updateCityServiceStat(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateCityServiceStat");
	}
}

export default CityServiceStatService;
