import gql from "graphql-tag";

export const messagesByClientTokenQuery = (fragment) => gql`
	${fragment}
	query MessagesByClientToken(
		$filter: FilterFindManyMessageInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		messagesByClientTokenV2(
			filter: $filter
			skip: $skip
			limit: $limit
			sort: $sort
		) {
			...messagesByClientTokenQueryFragment
		}
	}
`;

export const countMessagesQuery = () => gql`
	query CountMessages($filter: FilterCountMessageInput) {
		countMessagesV2(filter: $filter)
	}
`;

export const createMessageByProviderTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateMessageByProviderToken($record: CreateOneMessageInput!) {
		createMessageByProviderTokenV2(record: $record) {
			...createMessageByProviderTokenMutationFragment
		}
	}
`;

export const createMessageByClientTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateMessageByClientToken($record: CreateOneMessageInput!) {
		createMessageByClientTokenV2(record: $record) {
			...createMessageByClientTokenMutationFragment
		}
	}
`;

export const updateMessagesByProviderTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateMessagesByProviderToken(
		$record: UpdateManyMessageInput!
		$filter: FilterUpdateManyMessageInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		updateMessagesByProviderTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			limit: $limit
			sort: $sort
		) {
			...updateMessagesByProviderTokenMutationFragment
		}
	}
`;

export const updateMessagesByClientTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateMessagesByClientToken(
		$record: UpdateManyMessageInput!
		$filter: FilterUpdateManyMessageInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		updateMessagesByClientTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			limit: $limit
			sort: $sort
		) {
			...updateMessagesByClientTokenMutationFragment
		}
	}
`;
