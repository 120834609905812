import gql from "graphql-tag";

export const isCityServiceStatQuery = (fragment) => gql`
	${fragment}
	query IsCityServiceStat($uri: String!) {
		isCityServiceStat(uri: $uri) {
			...isCityServiceStatQueryFragment
		}
	}
`;

export const cityServiceStatQuery = (fragment) => gql`
	${fragment}
	query CityServiceStatV2(
		$filter: FilterFindOneCityServiceStatInput
		$sort: JSON
		$skip: Int
	) {
		cityServiceStatV2(filter: $filter, sort: $sort, skip: $skip) {
			...cityServiceStatQueryFragment
		}
	}
`;

export const paginateCitiesServicesStatsQuery = (fragment) => gql`
	${fragment}
	query PaginateCitiesServicesStats(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyCityServiceStatInput
		$sort: JSON
	) {
		paginateCitiesServicesStatsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateCitiesServicesStatsQueryFragment
		}
	}
`;

export const updateCityServiceStatMutation = (fragment) => gql`
	${fragment}
	mutation UpdateCityServiceStat(
		$record: UpdateOneCityServiceStatInput!
		$filter: FilterUpdateOneCityServiceStatInput
		$skip: Int
		$sort: JSON
	) {
		updateCityServiceStatV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateCityServiceStatMutationFragment
		}
	}
`;
