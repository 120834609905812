import MainService from "../Main";
import * as operations from "./Referrals.graphql";

class ReferralsService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default ReferralsService;
