import MainService from "../Main";
import * as operations from "./State.graphql.js";

class StateService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	// isState(variables = {}, options = {}) {
	// 	return this.query(variables, options, "isState");
	// }

	// getState(variables = {}, options = {}) {
	// 	return this.query(variables, options, "state");
	// }

	// getStates(variables = {}, options = {}) {
	// 	return this.query(variables, options, "states");
	// }

	// paginateStates(variables = {}, options = {}) {
	// 	return this.query(variables, options, "paginateStates");
	// }

	// updateState(variables = {}, options = {}) {
	// 	return this.mutation(variables, options, "updateState");
	// }
}

export default StateService;
