import MainService from "../Main";
import * as operations from "./ServiceSubscription.graphql.js";

class ServiceSubscriptionService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default ServiceSubscriptionService;
