import gql from "graphql-tag";

export const publishedCostGuidesQuery = (fragment) => gql`
	${fragment}
	query PublishedCostGuides {
		publishedCostGuides {
			...publishedCostGuidesQueryFragment
		}
	}
`;

export const publishedContentQuery = (fragment) => gql`
	${fragment}
	query PublishedContent($folder: String!, $slug: String!) {
		publishedContent(folder: $folder, slug: $slug) {
			...publishedContentQueryFragment
		}
	}
`;

export const publishedCoursesQuery = (fragment) => gql`
	${fragment}
	query PublishedCourses {
		publishedCourses {
			...publishedCoursesQueryFragment
		}
	}
`;
