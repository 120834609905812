import MainService from "../Main";
import * as operations from "./Report.graphql";

class ReportService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
	createReportByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createReportByToken");
	}

	paginateReports(variables = {}, options = {}) {
		return this.query(variables, options, "paginateReports");
	}

	updateReport(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateReport");
	}
}

export default ReportService;
