import MainService from "../Main";
import * as operations from "./Content.graphql";

class ContentService extends MainService {
	constructor(...args) {
		super(...args);
		this.patch(operations);
	}

	publishedCostGuides(variables, options = {}) {
		return this.query(
			variables,
			{ keepTypeName: true, ...options },
			"publishedCostGuides"
		);
	}

	publishedContent(variables, options = {}) {
		return this.query(
			variables,
			{ keepTypeName: true, ...options },
			"publishedContent"
		);
	}

	publishedCourses(variables, options = {}) {
		return this.query(
			variables,
			{ keepTypeName: true, ...options },
			"publishedCourses"
		);
	}
}

export default ContentService;
