import MainService from "../Main";
import * as operations from "./UploadMigrationStatus.graphql";

class UploadMigrationStatusService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default UploadMigrationStatusService;
