import MainService from "../Main";
import * as operations from "./Upload.graphql";

class UploadService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default UploadService;
