import MainService from "../Main";
import * as operations from "./Affinity.graphql";

class AffinityService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getAffinitiesByService(variables = {}, options = {}) {
		return this.query(variables, options, "affinitiesByService");
	}

	getAffinities(variables = {}, options = {}) {
		return this.query(variables, options, "affinitiesByService");
	}

	getAffinitiesByServiceKey(variables = {}, options = {}) {
		return this.query(variables, options, "affinitiesByServiceKey");
	}

	getAffinitiesByCategory(variables = {}, options = {}) {
		return this.query(variables, options, "affinitiesByCategory");
	}

	getAffinities(variables = {}, options = {}) {
		return this.query(variables, options, "affinities");
	}

	paginateAffinities(variables = {}, options = {}) {
		return this.query(variables, options, "paginateAffinities");
	}

	createAffinity(variables = {}, options = {}) {
		return this.mutation(variables, options, "createAffinity");
	}

	updateAffinity(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateAffinity");
	}

	deleteAffinity(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteAffinity");
	}
}

export default AffinityService;
