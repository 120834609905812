import gql from "graphql-tag";

export const servicesQuery = (fragment) => gql`
	${fragment}
	query Services(
		$sort: JSON
		$filter: FilterFindManyServiceInput
		$limit: Int
		$skip: Int
	) {
		servicesV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...servicesQueryFragment
		}
	}
`;

export const serviceQuery = (fragment) => gql`
	${fragment}
	query Service($sort: JSON, $filter: FilterFindOneServiceInput, $skip: Int) {
		serviceV2(filter: $filter, skip: $skip, sort: $sort) {
			...serviceQueryFragment
		}
	}
`;

export const countServicesQuery = (fragment) => gql`
	${fragment}
	query CountServices($filter: FilterCountServiceInput) {
		countServicesV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...countServicesQueryFragment
		}
	}
`;

export const paginateServicesQuery = (fragment) => gql`
	${fragment}
	query PaginateServices(
		$perPage: Int
		$filter: FilterFindManyServiceInput
		$sort: JSON
		$page: Int
	) {
		paginateServicesV2(
			perPage: $perPage
			filter: $filter
			sort: $sort
			page: $page
		) {
			...paginateServicesQueryFragment
		}
	}
`;

export const createServiceMutation = (fragment) => gql`
	${fragment}
	mutation CreateService($record: CreateOneServiceInput!) {
		createServiceV2(record: $record) {
			...createServiceMutationFragment
		}
	}
`;

export const updateServiceMutation = (fragment) => gql`
	${fragment}
	mutation UpdateService(
		$record: UpdateOneServiceInput!
		$filter: FilterUpdateOneServiceInput
		$skip: Int
		$sort: JSON
	) {
		updateServiceV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateServiceMutationFragment
		}
	}
`;

// export const servicesQuery = (fragment) => gql`
// 	${fragment}
// 	query Services($count: Boolean, $limit: Int, $filter: String, $sort: String) {
// 		services(count: $count, limit: $limit, filter: $filter, sort: $sort) {
// 			...servicesQueryFragment
// 		}
// 	}
// `;

// export const serviceQuery = (fragment) => gql`
// 	${fragment}
// 	query Service($_id: ID!, $questionFilter: String) {
// 		service(_id: $_id, questionFilter: $questionFilter) {
// 			...serviceQueryFragment
// 		}
// 	}
// `;

// export const isServiceQuery = (fragment) => gql`
// 	${fragment}
// 	query IsService($uri: String!) {
// 		isService(uri: $uri) {
// 			...isServiceQueryFragment
// 		}
// 	}
// `;

// export const serviceByKeyQuery = (fragment) => gql`
// 	${fragment}
// 	query ServiceByKey($serviceKey: ID!) {
// 		serviceByKey(key: $serviceKey) {
// 			...serviceByKeyQueryFragment
// 		}
// 	}
// `;
