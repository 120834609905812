import MainService from "../Main";
import * as operations from "./Service.graphql.js";

class ServiceService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getService(variables = {}, options = {}) {
		return this.query(variables, options, "service");
	}

	getServices(variables = {}, options = {}) {
		return this.query(variables, options, "services");
	}

	paginateServices(variables = {}, options = {}) {
		return this.query(variables, options, "paginateServices");
	}

	createService(variables = {}, options = {}) {
		return this.mutation(variables, options, "createService");
	}

	updateService(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateService");
	}

	/*****/
	serviceByKey(variables = {}, options = {}) {
		return this.query(variables, options, "serviceByKey");
	}

	getServiceByName(variables = {}, options = {}) {
		return this.query(variables, options, "serviceByName");
	}

	isService(variables = {}, options = {}) {
		return this.query(variables, options, "isService");
	}
}

export default ServiceService;
