import MainService from "../Main";
import * as operations from "./ProviderPassedLead.graphql";

class ProviderPassedLead extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getProviderPassedLeadsByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerPassedLeadsByToken");
	}

	getProviderPassedLeadByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerPassedLeadByToken");
	}

	createProviderPassedLeadByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createProviderPassedLeadByToken");
	}

	updateProviderPassedLeadByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderPassedLeadByToken");
	}
}

export default ProviderPassedLead;
