import gql from "graphql-tag";

export const sitemapQuery = (fragment) => gql`
	${fragment}
	query Sitemap($filter: FilterFindOneSitemapInput, $skip: Int, $sort: JSON) {
		sitemapV2(filter: $filter, skip: $skip, sort: $sort) {
			...sitemapQueryFragment
		}
	}
`;
