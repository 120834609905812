import MainService from "../Main";
import * as operations from "./Signup.graphql";

class SignupService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	signupWithFb(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/signupWithFb" },
			variables,
			options,
			"signupWithFb"
		);
	}

	signupWithGoogle(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/auth/signupWithGoogle" },
			variables,
			options,
			"signupWithGoogle"
		);
	}

	signupAsClient(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/clients/signup" },
			variables,
			options,
			"signupAsClient"
		);
	}

	signupAsPro(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: "/pro/signup" },
			variables,
			options,
			"signupAsPro"
		);
	}
}

export default SignupService;
