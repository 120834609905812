import gql from "graphql-tag";

export const providerServiceConfigurationsQuery = (fragment) => gql`
	${fragment}
	query ProviderServiceConfigurations(
		$filter: FilterFindManyProviderServiceConfigurationInput
		$sort: JSON
		$skip: Int
	) {
		providerServiceConfigurationsV2(filter: $filter, sort: $sort, skip: $skip) {
			...providerServiceConfigurationsQueryFragment
		}
	}
`;

export const providerServiceConfigurationsByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderServiceConfigurationsByTokenV2(
		$filter: FilterFindManyProviderServiceConfigurationInput
		$skip: Int
		$sort: JSON
		$limit: Int
	) {
		providerServiceConfigurationsByTokenV2(
			filter: $filter
			skip: $skip
			sort: $sort
			limit: $limit
		) {
			...providerServiceConfigurationsByTokenQueryFragment
		}
	}
`;

export const providerServiceConfigurationByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderServiceConfigurationByToken(
		$filter: FilterFindOneProviderServiceConfigurationInput
		$skip: Int
		$sort: JSON
	) {
		providerServiceConfigurationByTokenV2(
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...providerServiceConfigurationByTokenQueryFragment
		}
	}
`;

export const updateProviderServiceConfigurationMutation = gql`
	mutation UpdateProviderServiceConfiguration(
		$service: ID!
		$input: UpdateProviderServiceConfigurationInput
	) {
		updateProviderServiceConfiguration(service: $service, input: $input) {
			success
		}
	}
`;

export const updateProviderServiceConfigurationByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation UpdateProviderServiceConfigurationByToken(
		$record: UpdateOneProviderServiceConfigurationInput!
		$filter: FilterUpdateOneProviderServiceConfigurationInput
	) {
		updateProviderServiceConfigurationByTokenV2(
			record: $record
			filter: $filter
		) {
			...updateProviderServiceConfigurationByTokenMutationFragment
		}
	}
`;

export const updateProviderServiceConfigurationsByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation UpdateProviderServiceConfigurationsByToken(
		$record: UpdateManyProviderServiceConfigurationInput!
		$filter: FilterUpdateManyProviderServiceConfigurationInput
	) {
		updateProviderServiceConfigurationsByTokenV2(
			record: $record
			filter: $filter
		) {
			...updateProviderServiceConfigurationsByTokenMutationFragment
		}
	}
`;

export const createProviderServiceConfigurationByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation CreateProviderServiceConfigurationByToken(
		$record: CreateOneProviderServiceConfigurationInput!
	) {
		createProviderServiceConfigurationByTokenV2(record: $record) {
			...createProviderServiceConfigurationByTokenMutationFragment
		}
	}
`;

export const createProviderServiceConfigurationsByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation CreateProviderServiceConfigurationsByToken(
		$records: [CreateManyProviderServiceConfigurationInput!]!
	) {
		createProviderServiceConfigurationsByTokenV2(records: $records) {
			...createProviderServiceConfigurationsByTokenMutationFragment
		}
	}
`;

export const createOrUpdateProviderServiceConfigurationsByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation CreateOrUpdateProviderServiceConfigurationsByToken(
		$records: [CreateManyProviderServiceConfigurationInput!]!
	) {
		createOrUpdateProviderServiceConfigurationsByTokenV2(records: $records) {
			...createOrUpdateProviderServiceConfigurationsByTokenMutationFragment
		}
	}
`;

export const findOrCreateProviderServiceConfigurationMutation = (
	fragment
) => gql`
	${fragment}
	mutation FindOrCreateProviderServiceConfiguration(
		$input: FindOrCreateProviderServiceConfigurationInput
	) {
		findOrCreateProviderServiceConfiguration(input: $input) {
			...findOrCreateProviderServiceConfigurationMutationFragment
		}
	}
`;

export const countProviderServiceConfigurationsByTokenQuery = () => gql`
	query CountProviderServiceConfigurationsByToken(
		$filter: FilterCountProviderServiceConfigurationInput
	) {
		countProviderServiceConfigurationsByTokenV2(filter: $filter)
	}
`;
