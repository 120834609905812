import gql from "graphql-tag";

export const searchByCityServiceStatQuery = (fragment) => gql`
	${fragment}
	query SearchByCityServiceStat($_id: ID!) {
		searchByCityServiceStat(_id: $_id) {
			...searchByCityServiceStatQueryFragment
		}
	}
`;

export const searchQuery = (fragment) => gql`
	${fragment}
	query Search(
		$serviceKey: ID
		$zipcode: String = ""
		$answerKeyFilters: [String]
		$injectProId: ID
		$geoBound: GeoBoundInput
		$anonymousId: String
		$userId: ID
	) {
		search(
			serviceKey: $serviceKey
			zipcode: $zipcode
			answerKeyFilters: $answerKeyFilters
			injectProId: $injectProId
			geoBound: $geoBound
			anonymousId: $anonymousId
			userId: $userId
		) {
			...searchQueryFragment
		}
	}
`;

export const searchByLeadQuery = (fragment) => gql`
	${fragment}
	query SearchByLead($leadKey: ID!, $answerKeyFilters: [String]) {
		searchByLead(leadKey: $leadKey) {
			...searchByLeadQueryFragment
		}
	}
`;

export const searchByAutoBillQuery = (fragment) => gql`
	${fragment}
	query SearchByAutoBill($leadKey: ID!) {
		searchByAutoBill(leadKey: $leadKey) {
			...searchByAutoBillQueryFragment
		}
	}
`;

export const searchByProfileUriQuery = (fragment) => gql`
	${fragment}
	query SearchByProfileUri($uri: String!, $answerKeyFilters: [String]) {
		searchByProfileUri(uri: $uri) {
			...searchByProfileUriQueryFragment
		}
	}
`;

export const searchByNearMeUriQuery = (fragment) => gql`
	${fragment}
	query SearchByNearMeUri(
		$uri: String!
		$location: JSON
		$answerKeyFilters: [String]
	) {
		searchByNearMeUri(uri: $uri, location: $location) {
			...searchByNearMeUriQueryFragment
		}
	}
`;

export const searchByComboUriQuery = (fragment) => gql`
	${fragment}
	query SearchByComboUri($uri: String!, $answerKeyFilters: [String]) {
		searchByComboUri(uri: $uri) {
			...searchByComboUriQueryFragment
		}
	}
`;

export const searchByCityUriQuery = (fragment) => gql`
	${fragment}
	query SearchByCityUri($uri: String!) {
		searchByCityUri(uri: $uri) {
			...searchByCityUriQueryFragment
		}
	}
`;

export const searchByContentPageUriQuery = (fragment) => gql`
	${fragment}
	query SearchByContentPageUri($uri: String!) {
		searchByContentPageUri(uri: $uri) {
			...searchByContentPageUriQueryFragment
		}
	}
`;

export const searchByStateUriQuery = (fragment) => gql`
	${fragment}
	query SearchByStateUri($uri: String!) {
		searchByStateUri(uri: $uri) {
			...searchByStateUriQueryFragment
		}
	}
`;

export const searchServiceAutoCompleteQuery = (fragment) => gql`
	${fragment}
	query SearchServiceAutoComplete(
		$prefix: String!
		$active: Boolean
		$midtail: Boolean
		$size: Int
	) {
		searchServiceAutoComplete(
			prefix: $prefix
			active: $active
			midtail: $midtail
			size: $size
		) {
			...searchServiceAutoCompleteQueryFragment
		}
	}
`;

export const searchByMapQuery = (fragment) => gql`
	${fragment}
	query SearchByMap(
		$serviceKey: ID
		$zipcode: String = ""
		$answerKeyFilters: [String]
		$injectProId: ID
		$geoBound: GeoBoundInput
		$anonymousId: String
		$userId: ID
		$page: Int
		$perPage: Int
	) {
		searchByMap(
			serviceKey: $serviceKey
			zipcode: $zipcode
			answerKeyFilters: $answerKeyFilters
			injectProId: $injectProId
			geoBound: $geoBound
			anonymousId: $anonymousId
			userId: $userId
			page: $page
			perPage: $perPage
		) {
			...searchByMapQueryFragment
		}
	}
`;
