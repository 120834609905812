import gql from "graphql-tag";

export const purchaseCreditPackageMutation = (fragment) => gql`
	${fragment}
	mutation PurchaseCreditPackage($price: Int!, $value: Int!) {
		purchaseCreditPackage(price: $price, value: $value) {
			...purchaseCreditPackageMutationFragment
		}
	}
`;
