import MainService from "../Main";
import * as operations from "./QuestionPricing.graphql";

class QuestionPricingService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getQuestionPricing(variables = {}, options = {}) {
		return this.query(variables, options, "questionPricing");
	}

	questionPricingNames(variables = {}, options = {}) {
		return this.query(variables, options, "questionPricingNames");
	}

	questionPricingByService(variables = {}, options = {}) {
		return this.query(variables, options, "questionPricingByService");
	}

	questionPricingByArrangement(variables = {}, options = {}) {
		return this.query(variables, options, "questionPricingByArrangement");
	}

	questionPricingByName(variables = {}, options = {}) {
		return this.query(variables, options, "questionPricingByName");
	}

	createQuestionPricing(variables = {}, options = {}) {
		return this.mutation(variables, options, "createQuestionPricing");
	}

	updateQuestionPricing(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateQuestionPricing");
	}

	removeQuestionPricing(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeQuestionPricing");
	}

	deleteQuestionPricingByName(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteQuestionPricingByName");
	}
}

export default QuestionPricingService;
