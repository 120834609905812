import gql from "graphql-tag";

export const subscriptionProductsQuery = (fragment) => gql`
	${fragment}
	query SubscriptionProducts {
		subscriptionProducts {
			...subscriptionProductsQueryFragment
		}
	}
`;

export const subscriptionCheckoutSessionUrlQuery = () => gql`
	query SubscriptionCheckoutSessionUrl(
		$subscription_id: ID!
		$success_url: String!
		$cancel_url: String!
	) {
		subscriptionCheckoutSessionUrl(
			subscription_id: $subscription_id
			success_url: $success_url
			cancel_url: $cancel_url
		)
	}
`;

export const subscriptionPortalSessionUrlQuery = () => gql`
	query SubscriptionPortalSessionUrl($return_url: String!) {
		subscriptionPortalSessionUrl(return_url: $return_url)
	}
`;

export const subscriptionPortalConfigurationQuery = (fragment) => gql`
	${fragment}
	query SubscriptionPortalConfiguration {
		subscriptionPortalConfiguration {
			...subscriptionPortalConfigurationQueryFragment
		}
	}
`;

export const updateSubscriptionPortalConfigurationMutation = (fragment) => gql`
	${fragment}
	mutation UpdateSubscriptionPortalConfiguration($data: JSON!) {
		updateSubscriptionPortalConfiguration(data: $data) {
			...updateSubscriptionPortalConfigurationMutationFragment
		}
	}
`;
