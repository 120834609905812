import MainService from "../Main";
import * as operations from "./Lead.graphql";

class LeadService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	countLeadsByToken(variables = {}, options = {}) {
		return this.query(variables, options, "countLeadsByToken");
	}

	getLeads(variables = {}, options = {}) {
		return this.query(variables, options, "leads");
	}

	paginateLeads(variables = {}, options = {}) {
		return this.query(variables, options, "paginateLeads");
	}

	getMatchedLeads(variables = {}, options = {}) {
		return this.query(variables, options, "matchedLeads");
	}

	getMatchedLead(variables = {}, options = {}) {
		return this.query(variables, options, "matchedLead");
	}

	getLeadByToken(variables = {}, options = {}) {
		return this.query(variables, options, "leadByToken");
	}

	getLeadsByToken(variables = {}, options = {}) {
		return this.query(variables, options, "leadsByToken");
	}

	addLeadPicks(variables = {}, options = {}) {
		return this.mutation(variables, options, "addLeadPicks");
	}

	createLead(variables = {}, options = {}) {
		return this.mutation(variables, options, "createLead");
	}

	updateLead(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateLeadByToken");
	}

	approveLead(variables = {}, options = {}) {
		return this.mutation(variables, options, "approveLead");
	}

	deleteLead(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteLead");
	}

	spamLead(variables = {}, options = {}) {
		return this.mutation(variables, options, "spamLead");
	}

	updateJobStatus(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateJobStatus");
	}

	findOpenLead(variables = {}, options = {}) {
		return this.query(variables, options, "findOpenLead");
	}

	addLeadPicks(variables = {}, options = {}) {
		return this.mutation(variables, options, "addLeadPicks");
	}
}

export default LeadService;
