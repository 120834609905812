import apolloClient from "@framework/apollo/client";
import MainService from "../Main";
import * as operations from "./QuestionTag.graphql";
import { isBrowser } from "@utils/Browser";

let client;
if (isBrowser) {
	client = apolloClient({
		ssr: false,
		window,
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
			mutate: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

class TagService extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}

	/**
	 * Fetches tags.
	 *
	 * @param {Object} apolloClient - GraphQL client
	 * @returns {Q.Promise<any> | Promise<void> | Promise<T | never> | *}
	 */
	static getTags(variables = {}, options = {}, apolloClient = client) {
		const { fetchPolicy, returnQuery } = options;
		const { tagsQuery: query } = this.queries;
		if (!query) throw new Error(`Must have tagsQuery in ${this.dir}`);
		if (!returnQuery) return query;
		return apolloClient
			.query({
				query,
				variables,
				fetchPolicy: fetchPolicy || this.options.fetchPolicy,
			})
			.then(({ data }) => data.tags)
			.catch((e) => {
				console.error(`Error fetching tags:`, e);
				throw e;
			});
	}

	/**
	 * Updates a tag
	 *
	 * @param {Object} apolloClient - GraphQL client
	 * @param {Object} payload
	 * @returns {Promise<T | never>}
	 */
	static updateTag({ input, _id }, options = {}, apolloClient = client) {
		const { fetchPolicy, returnMutation, validations } = options;
		const { updateTagMutation: mutation } = this.mutations;
		if (!mutation)
			throw new Error(`Must have updateTagMutation in ${this.dir}`);
		return apolloClient
			.mutate({
				mutation,
				variables: { _id, input },
				fetchPolicy,
			})
			.then(({ data }) => data.updateTag)
			.catch((e) => {
				console.log(`Error updating tag (${_id}):`, e);
				throw e;
			});
	}

	/**
	 * Creates a tag.
	 *
	 * @param {Object} apolloClient - GraphQL client
	 * @param {Object} payload
	 * @returns {Promise<T | never>}
	 */
	static createTag({ input }, options = {}, apolloClient = client) {
		const { fetchPolicy, returnMutation, validations } = options;
		const { createTagMutation: mutation } = this.mutations;
		if (!mutation)
			throw new Error(`Must have createTagMutation in ${this.dir}`);
		if (returnMutation) return { mutation, validation };
		return apolloClient
			.mutate({
				mutation,
				variables: { input },
				fetchPolicy,
			})
			.then(({ data }) => data.createTag)
			.catch((e) => {
				console.log("Error creating tag:", e);
				throw e;
			});
	}
}

export default TagService;
