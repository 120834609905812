import gql from "graphql-tag";

export const imageUploadsQuery = (fragment) => gql`
	${fragment}
	query ImageUploads($limit: Int, $userType: ImageUploadUserTypeEnum) {
		imageUploads(limit: $limit, userType: $userType) {
			...imageUploadsQueryFragment
		}
	}
`;

export const approveImageUploadMutation = (fragment) => gql`
	${fragment}
	mutation ApproveImageUpload($key: ID!, $userType: ImageUploadUserTypeEnum!) {
		approveImageUpload(key: $key, userType: $userType) {
			...approveImageUploadMutationFragment
		}
	}
`;
