import gql from "graphql-tag";

export const referralsByReferrerTokenQuery = (fragment) => gql`
	${fragment}
	query ReferralsByReferrerToken(
		$filter: FilterFindManyReferralInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		referralsByReferrerTokenV2(
			filter: $filter
			limit: $limit
			skip: $skip
			sort: $sort
		) {
			...referralsByReferrerTokenQueryFragment
		}
	}
`;

export const getReferralConfigurationQuery = (fragment) => gql`
	${fragment}
	query GetReferralConfiguration {
		getReferralConfiguration {
			...getReferralConfigurationQueryFragment
		}
	}
`;
