import MainService from "../Main";
import * as operations from "./Common.graphql";

class CommonService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getSocketCredentials(variables = {}, options = {}) {
		return this.query(variables, options, "socketCredentials");
	}
}

export default CommonService;
