import MainService from "../Main";
import * as operations from "./ProviderServiceConfiguration.graphql";

class ProviderServiceConfiguration extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}

	getProviderServiceConfigurations(variables = {}, options = {}) {
		return this.query(variables, options, "providerServiceConfigurations");
	}

	getProviderServiceConfigurationsByToken(variables = {}, options = {}) {
		return this.query(
			variables,
			options,
			"providerServiceConfigurationsByToken"
		);
	}

	getProviderServiceConfigurationByToken(variables = {}, options = {}) {
		return this.query(
			variables,
			options,
			"providerServiceConfigurationByToken"
		);
	}

	getProviderServiceConfiguration(variables = {}, options = {}) {
		return this.query(variables, options, "providerServiceConfiguration");
	}

	createProviderServiceConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createProviderServiceConfiguration"
		);
	}

	countProviderServiceConfigurationsByToken(variables = {}, options = {}) {
		return this.query(
			variables,
			options,
			"countProviderServiceConfigurationsByToken"
		);
	}

	createProviderServiceConfigurationByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createProviderServiceConfigurationByToken"
		);
	}

	createProviderServiceConfigurationsByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createProviderServiceConfigurationsByToken"
		);
	}

	updateProviderServiceConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderServiceConfiguration"
		);
	}

	updateProviderServiceConfigurationByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderServiceConfigurationByToken"
		);
	}

	updateProviderServiceConfigurationsByToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateProviderServiceConfigurationsByToken"
		);
	}

	findOrCreateProviderServiceConfiguration(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"findOrCreateProviderServiceConfiguration"
		);
	}
}

export default ProviderServiceConfiguration;
