import MainService from "../Main";
import * as operations from "./ReviewComment.graphql";

class ReviewCommentService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	createReviewCommentByProviderToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createReviewCommentByProviderToken"
		);
	}

	createReviewCommentByClientToken(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createReviewCommentByClientToken"
		);
	}
}

export default ReviewCommentService;
