import gql from "graphql-tag";

export const resetPasswordMutation = (fragment) => gql`
	${fragment}
	mutation ResetPassword($email: String!) {
		resetPassword(email: $email) {
			...resetPasswordMutationFragment
		}
	}
`;
