import gql from "graphql-tag";

export const categoriesQuery = (fragment) => gql`
	${fragment}
	query Categories(
		$sort: JSON
		$filter: FilterFindManyCategoryInput
		$limit: Int
		$skip: Int
	) {
		categoriesV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...categoriesQueryFragment
		}
	}
`;

export const categoryQuery = (fragment) => gql`
	${fragment}
	query Category($sort: JSON, $filter: FilterFindOneCategoryInput, $skip: Int) {
		categoryV2(filter: $filter, skip: $skip, sort: $sort) {
			...categoryQueryFragment
		}
	}
`;

export const countCategoriesQuery = (fragment) => gql`
	${fragment}
	query CountCategories($filter: FilterCountCategoryInput) {
		countCategoriesV2(
			limit: $limit
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...countCategoriesQueryFragment
		}
	}
`;

export const paginateCategoriesQuery = (fragment) => gql`
	${fragment}
	query PaginateCategories(
		$sort: JSON
		$filter: FilterFindManyCategoryInput
		$page: Int
		$perPage: Int
	) {
		paginateCategoriesV2(
			sort: $sort
			filter: $filter
			page: $page
			perPage: $perPage
		) {
			...paginateCategoriesQueryFragment
		}
	}
`;

export const createCategoryMutation = (fragment) => gql`
	${fragment}
	mutation CreateCategoryV2($record: CreateOneCategoryInput!) {
		createCategoryV2(record: $record) {
			...createCategoryMutationFragment
		}
	}
`;

export const updateCategoryMutation = (fragment) => gql`
	${fragment}
	mutation UpdateCategory(
		$record: UpdateOneCategoryInput!
		$filter: FilterUpdateOneCategoryInput
		$sort: JSON
		$skip: Int
	) {
		updateCategoryV2(
			record: $record
			filter: $filter
			sort: $sort
			skip: $skip
		) {
			...updateCategoryMutationFragment
		}
	}
`;

export const isCategoryQuery = (fragment) => gql`
	${fragment}
	query IsCategory($uri: String!, $filter: String, $sort: String, $limit: Int) {
		isCategory(uri: $uri) {
			...isCategoryQueryFragment
		}
	}
`;
