import MainService from "../Main";
import * as operations from "./Inbox.graphql";

class InboxService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getProviderInbox(variables = {}, options = {}) {
		return this.query(variables, options, "providerInbox");
	}

	getClientInbox(variables = {}, options = {}) {
		return this.query(variables, options, "clientInbox");
	}
}

export default InboxService;
