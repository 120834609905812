import gql from "graphql-tag";

export const socketCredentialsQuery = (fragment) => gql`
	${fragment}
	query SocketCredentials {
		socketCredentialsV2 {
			...socketCredentialsQueryFragment
		}
	}
`;
