import gql from "graphql-tag";

export const signupAsAdminMutation = (fragment) => gql`
	${fragment}
	mutation SignupAsAdmin($record: CreateOneAccountInput!) {
		signupAsAdmin(record: $record) {
			...signupAsAdminMutationFragment
		}
	}
`;
