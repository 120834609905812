import gql from "graphql-tag";

export const createProviderPassedLeadByTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateProviderPassedLeadByToken(
		$record: CreateOneProviderPassedLeadInput!
	) {
		createProviderPassedLeadByTokenV2(record: $record) {
			...createProviderPassedLeadByTokenMutationFragment
		}
	}
`;

export const updateProviderPassedLeadByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderPassedLeadByToken(
		$filter: FilterUpdateOneProviderPassedLeadInput
		$record: UpdateOneProviderPassedLeadInput!
		$sort: JSON
		$skip: Int
	) {
		updateProviderPassedLeadByTokenV2(
			filter: $filter
			record: $record
			sort: $sort
			skip: $skip
		) {
			...updateProviderPassedLeadByTokenMutationFragment
		}
	}
`;

export const providerPassedLeadsByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderPassedLeadsByToken(
		$filter: FilterFindManyProviderPassedLeadInput
		$sort: JSON
		$limit: Int
		$skip: Int
	) {
		providerPassedLeadsByTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...providerPassedLeadsByTokenQueryFragment
		}
	}
`;

export const providerPassedLeadByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderPassedLeadByToken(
		$filter: FilterFindOneProviderPassedLeadInput
		$sort: JSON
		$skip: Int
	) {
		providerPassedLeadByTokenV2(filter: $filter, sort: $sort, skip: $skip) {
			...providerPassedLeadByTokenQueryFragment
		}
	}
`;
