import gql from "graphql-tag";

export const isCityQuery = (fragment) => gql`
	${fragment}
	query IsCity($uri: String!) {
		isCity(uri: $uri) {
			...isCityQueryFragment
		}
	}
`;

export const cityQuery = (fragment) => gql`
	${fragment}
	query City($filter: FilterFindOneCityInput, $sort: JSON, $skip: Int) {
		cityV2(filter: $filter, sort: $sort, skip: $skip) {
			...cityQueryFragment
		}
	}
`;

export const citiesQuery = (fragment) => gql`
	${fragment}
	query Cities(
		$limit: Int
		$filter: FilterFindManyCityInput
		$sort: JSON
		$skip: Int
	) {
		citiesV2(limit: $limit, filter: $filter, sort: $sort, skip: $skip) {
			...citiesQueryFragment
		}
	}
`;

export const paginateCitiesQuery = (fragment) => gql`
	${fragment}
	query PaginateCities(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyCityInput
		$sort: JSON
	) {
		paginateCitiesV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateCitiesQueryFragment
		}
	}
`;

export const updateCityMutation = (fragment) => gql`
	${fragment}
	mutation UpdateCity(
		$record: UpdateOneCityInput!
		$filter: FilterUpdateOneCityInput
		$skip: Int
		$sort: JSON
	) {
		updateCityV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateCityMutationFragment
		}
	}
`;
