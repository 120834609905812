import MainService from "../Main";
import * as operations from "./Geo.graphql";

class GeoService extends MainService {
	constructor(...args) {
		super(...args);
		this.patch(operations);
	}

	geoBoundaryByProvider(variables, options = {}) {
		return this.query(variables, options, "geoBoundaryByProvider");
	}

	geoip(variables, options = {}) {
		return this.query(variables, options, "geoip");
	}

	geoLocationByZip(variables, options = {}) {
		return this.query(variables, options, "geoLocationByZip");
	}
	
}

export default GeoService;
