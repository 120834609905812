import gql from "graphql-tag";

export const paginateFAQSQuery = (fragment) => gql`
	${fragment}
	query paginateFAQS(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyFAQInput
		$sort: JSON
	) {
		paginateFAQSV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateFAQSQueryFragment
		}
	}
`;

export const updateFAQMutation = (fragment) => gql`
	${fragment}
	mutation UpdateFAQ(
		$record: UpdateOneFAQInput!
		$filter: FilterUpdateOneFAQInput
		$skip: Int
		$sort: JSON
	) {
		updateFAQV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateFAQMutationFragment
		}
	}
`;

export const FAQQuery = (fragment) => gql`
	${fragment}
	query FAQ($sort: JSON, $filter: FilterFindOneFAQInput, $skip: Int) {
		FAQV2(filter: $filter, skip: $skip, sort: $sort) {
			...FAQQueryFragment
		}
	}
`;

export const createFAQMutation = (fragment) => gql`
	${fragment}

	mutation CreateFAQ($record: CreateOneFAQInput!) {
		createFAQV2(record: $record) {
			...createFAQMutationFragment
		}
	}
`;

export const countFAQSQuery = () => gql`
	query CountFAQS($filter: FilterCountFAQInput) {
		countFAQSV2(filter: $filter)
	}
`;

export const removeFAQMutation = (fragment) => gql`
	${fragment}
	mutation RemoveFAQ($filter: FilterRemoveOneFAQInput, $sort: JSON) {
		removeFAQV2(filter: $filter, sort: $sort) {
			...removeFAQMutationFragment
		}
	}
`;
