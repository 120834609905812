import MainService from "../Main";
import * as operations from "./ProviderSavedReply.graphql";

class ProviderSavedReplyService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getProviderSavedRepliesByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerSavedRepliesByToken");
	}

	updateProviderSavedReplyByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderSavedReplyByToken");
	}

	createProviderSavedReplyByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createProviderSavedReplyByToken");
	}

	removeProviderSavedReplyByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderSavedReplyByToken");
	}
}

export default ProviderSavedReplyService;
