import MainService from "../Main";
import * as operations from "./FunnelStepConfigurationTag.graphql";

class FunnelStepConfigurationTag extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}

	funnelStepConfigurationTags(variables = {}, options = {}) {
		return this.query(variables, options, "funnelStepConfigurationTags");
	}

	funnelsStepsConfigurationsTags(variables = {}, options = {}) {
		return this.query(variables, options, "funnelsStepsConfigurationsTags");
	}

	createFunnelStepConfigurationTag(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"createFunnelStepConfigurationTag"
		);
	}

	updateFunnelStepConfigurationTag(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"updateFunnelStepConfigurationTag"
		);
	}

	removeFunnelStepConfigurationTag(variables = {}, options = {}) {
		return this.mutation(
			variables,
			options,
			"removeFunnelStepConfigurationTag"
		);
	}
}

export default FunnelStepConfigurationTag;
