import MainService from "../Main";
import * as operations from "./CityCategoryStat.graphql";

class CityCategoryStatService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	isCityCategoryStat(variables = {}, options = {}) {
		return this.query(variables, options, "isCityCategoryStat");
	}

	getCityCategoryStat(variables = {}, options = {}) {
		return this.query(variables, options, "cityCategoryStat");
	}
}

export default CityCategoryStatService;
