import gql from "graphql-tag";

export const providerQuery = (fragment) => gql`
	${fragment}
	query Provider($filter: FilterFindOneProviderInput) {
		providerV2(filter: $filter) {
			...providerQueryFragment
		}
	}
`;

export const providerByTokenQuery = (fragment) => gql`
	${fragment}
	query ProviderByToken {
		providerByTokenV2 {
			...providerByTokenQueryFragment
		}
	}
`;

export const updateProviderByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderByToken($record: UpdateOneProviderInput!) {
		updateProviderByTokenV2(record: $record) {
			...updateProviderByTokenMutationFragment
		}
	}
`;

export const updateProviderNotificationByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderNotificationByToken(
		$input: UpdateProviderNotificationInput
	) {
		updateProviderNotificationByToken(input: $input) {
			...updateProviderNotificationByTokenMutationFragment
		}
	}
`;

export const updateAllSmsProviderNotificationsByTokenMutation = (
	fragment
) => gql`
	${fragment}
	mutation UpdateAllSmsProviderNotificationsByToken($value: Boolean!) {
		updateAllSmsProviderNotificationsByToken(value: $value) {
			...updateAllSmsProviderNotificationsByTokenMutationFragment
		}
	}
`;

export const updateProviderBusinessHoursByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderBusinessHoursByToken(
		$record: UpdateOneProviderBusinessHourInput!
	) {
		updateProviderBusinessHourByTokenV2(record: $record) {
			...updateProviderBusinessHoursByTokenMutationFragment
		}
	}
`;

export const paginateProvidersQuery = (fragment) => gql`
	${fragment}
	query PaginateProviders(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyProviderInput
		$sort: JSON
	) {
		paginateProvidersV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateProvidersQueryFragment
		}
	}
`;

export const updateProviderPrivilegedMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderPrivilegedV2(
		$record: UpdateOneProviderInput!
		$filter: FilterUpdateOneProviderInput
		$skip: Int
		$sort: JSON
	) {
		updateProviderPrivilegedV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateProviderPrivilegedMutationFragment
		}
	}
`;

export const getProviderProfileImagesQuery = (fragment) => gql`
	${fragment}
	query GetProviderProfileImages(
		$providerIds: [MongoID]
		$height: Int
		$width: Int
	) {
		getProviderProfileImages(
			providerIds: $providerIds
			height: $height
			width: $width
		) {
			...getProviderProfileImagesQueryFragment
		}
	}
`;

export const getProviderBusinessLocationMarkerMapQuery = () => gql`
	query GetProviderBusinessLocationMarkerMap(
		$providerId: MongoID!
		$height: Int
		$width: Int
	) {
		getProviderBusinessLocationMarkerMap(
			providerId: $providerId
			height: $height
			width: $width
		)
	}
`;

export const updateProviderSubscriptionMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderSubscription($subscription_id: ID!, $service_id: ID!) {
		updateProviderSubscription(
			subscription_id: $subscription_id
			service_id: $service_id
		) {
			...updateProviderSubscriptionMutationFragment
		}
	}
`;
