import MainService from "../Main";
import * as operations from "./ProviderFunnelStep.graphql";

class ProviderFunnelStepService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	completeProviderFunnelStep(variables = {}, options = {}) {
		return this.mutation(variables, options, "completeProviderFunnelStep");
	}

	providerFunnelCurrentStep(variables = {}, options = {}) {
		return this.query(variables, options, "providerFunnelCurrentStep");
	}

	providerFunnelNextStep(variables = {}, options = {}) {
		return this.rest(
			{ method: "get", url: "/pro/next/step" },
			variables,
			options,
			"providerFunnelNextStep"
		);
	}
}

export default ProviderFunnelStepService;
