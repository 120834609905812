import MainService from "../Main";
import * as operations from "./Review.graphql";

class ReviewService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getReviews(variables = {}, options = {}) {
		return this.query(variables, options, "reviews");
	}

	paginateReviews(variables = {}, options = {}) {
		return this.query(variables, options, "paginateReviews");
	}

	getReviewsByProviderToken(variables = {}, options = {}) {
		return this.query(variables, options, "reviewsByProviderToken");
	}

	getReviewsByClientToken(variables = {}, options = {}) {
		return this.query(variables, options, "reviewsByClientToken");
	}

	countReviews(variables = {}, options = {}) {
		return this.query(variables, options, "countReviews");
	}

	createReviewByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "createReviewByToken");
	}

	updateReview(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateReview");
	}

	approveReview(variables = {}, options = {}) {
		return this.mutation(variables, options, "approveReview");
	}

	declineReview(variables = {}, options = {}) {
		return this.mutation(variables, options, "declineReview");
	}

	removeReview(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeReview");
	}
}

export default ReviewService;
