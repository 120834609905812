import MainService from "../Main";
import * as operations from "./FAQ.graphql";

class FAQService extends MainService {
	constructor(options = {}) {
		super(options);
		this.patch(operations);
	}
}

export default FAQService;
