import gql from "graphql-tag";

export const serviceSubscriptionsQuery = (fragment) => gql`
	${fragment}
	query ServiceSubscriptions(
		$limit: Int
		$skip: Int
		$filter: FilterFindManyServiceSubscriptionInput
		$sort: JSON
	) {
		serviceSubscriptionsV2(
			limit: $limit
			skip: $skip
			filter: $filter
			sort: $sort
		) {
			...serviceSubscriptionsQueryFragment
		}
	}
`;

export const serviceSubscriptionQuery = (fragment) => gql`
	${fragment}
	query ServiceSubscription(
		$skip: Int
		$filter: FilterFindOneServiceSubscriptionInput
		$sort: JSON
	) {
		serviceSubscriptionV2(skip: $skip, filter: $filter, sort: $sort) {
			...serviceSubscriptionQueryFragment
		}
	}
`;

export const paginateServiceSubscriptionsQuery = (fragment) => gql`
	${fragment}
	query PaginateServiceSubscriptions(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyServiceSubscriptionInput
		$sort: JSON
	) {
		paginateServiceSubscriptionsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateServiceSubscriptionsQueryFragment
		}
	}
`;

export const updateServiceSubscriptionMutation = (fragment) => gql`
	${fragment}
	mutation UpdateServiceSubscription(
		$record: UpdateOneServiceSubscriptionInput!
		$filter: FilterUpdateOneServiceSubscriptionInput
		$skip: Int
		$sort: JSON
	) {
		updateServiceSubscriptionV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateServiceSubscriptionMutationFragment
		}
	}
`;

export const createServiceSubscriptionMutation = (fragment) => gql`
	${fragment}
	mutation CreateServiceSubscription(
		$record: CreateOneServiceSubscriptionInput!
	) {
		createServiceSubscriptionV2(record: $record) {
			...createServiceSubscriptionMutationFragment
		}
	}
`;
