import MainService from "../Main";
import * as operations from "./ProviderAlerts.graphql";

class ProviderAlertsService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getProviderAlertsByToken(variables = {}, options = {}) {
		return this.query(variables, options, "providerAlertsByToken");
	}

	removeProviderAlertByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeProviderAlertByToken");
	}

	updateProviderAlertByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderAlertByToken");
	}

	updateProviderAlertsByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateProviderAlertsByToken");
	}
}

export default ProviderAlertsService;
