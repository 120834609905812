import MainService from "../Main";
import * as operations from "./Client.graphql";

class ClientService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	client(variables = {}, options = {}) {
		return this.query(variables, options, "client");
	}

	clientByToken(variables = {}, options = {}) {
		return this.query(variables, options, "clientByToken");
	}

	createClient(variables = {}, options = {}) {
		return this.rest(
			{
				method: "post",
				url: "/client/create",
			},
			variables,
			options,
			"createClient"
		);
	}

	updateClient(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateClient");
	}

	updateClientByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateClientByToken");
	}

	deleteClient(variables = {}, options = {}) {
		return this.mutation(variables, options, "deleteClient");
	}

	removeClientByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeClientByToken");
	}

	paginateClients(variables = {}, options = {}) {
		return this.query(variables, options, "paginateClients");
	}

	updateClientPrivileged(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateClientPrivileged");
	}
}

export default ClientService;
