import gql from "graphql-tag";

export const addProviderMediaPicturesMutation = (fragment) => gql`
	${fragment}
	mutation AddProviderMediaPictures($hashes: [ID]!) {
		addProviderMediaPictures(hashes: $hashes) {
			...addProviderMediaPicturesMutationFragment
		}
	}
`;

export const addProviderMediaVideoMutation = (fragment) => gql`
	${fragment}
	mutation AddProviderMediaPictures($url: String!) {
		addProviderMediaVideo(url: $url) {
			...addProviderMediaVideoMutationFragment
		}
	}
`;

export const rotateProviderMediaPictureByKeyMutation = (fragment) => gql`
	${fragment}
	mutation RotateProviderMediaPictureByKey($key: ID!) {
		rotateProviderMediaPictureByKey(key: $key) {
			...rotateProviderMediaPictureByKeyMutationFragment
		}
	}
`;

export const moveProviderMediaByKeyMutation = (fragment) => gql`
	${fragment}
	mutation MoveProviderMediaByKey(
		$direction: ProviderMediaDirectionEnum!
		$key: ID!
	) {
		moveProviderMediaByKey(key: $key, direction: $direction) {
			...moveProviderMediaByKeyMutationFragment
		}
	}
`;

export const removeProviderMediaPictureByKeyMutation = (fragment) => gql`
	${fragment}
	mutation RemoveProviderMediaPictureByKey($key: ID!) {
		removeProviderMediaPictureByKey(key: $key) {
			...removeProviderMediaPictureByKeyMutationFragment
		}
	}
`;

export const removeProviderMediaVideoByKeyMutation = (fragment) => gql`
	${fragment}
	mutation RemoveProviderMediaVideoByKey($key: ID!) {
		removeProviderMediaVideoByKey(key: $key) {
			...removeProviderMediaVideoByKeyMutationFragment
		}
	}
`;

export const updateProviderMediaCaptionMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderMediaCaption(
		$key: ID!
		$type: EnumProviderMediaMedia_type!
		$caption: String
	) {
		updateProviderMediaCaption(key: $key, type: $type, caption: $caption) {
			...updateProviderMediaCaptionMutationFragment
		}
	}
`;

export const updateProviderMediaPictureHashMutation = (fragment) => gql`
	${fragment}
	mutation UpdateProviderMediaPictureHash($key: ID!, $hash: ID!) {
		updateProviderMediaPictureHash(key: $key, hash: $hash) {
			...updateProviderMediaPictureHashMutationFragment
		}
	}
`;

export const approveImageUploadsMutation = (fragment) => gql`
	${fragment}
	mutation ApproveImageUploads($keys: [ID]!) {
		approveImageUploads(keys: $keys) {
			...approveImageUploadsMutationFragment
		}
	}
`;
