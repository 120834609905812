import gql from "graphql-tag";

export const stateQuery = (fragment) => gql`
	${fragment}
	query State($skip: Int, $filter: FilterFindOneStateInput, $sort: JSON) {
		stateV2(skip: $skip, filter: $filter, sort: $sort) {
			...stateQueryFragment
		}
	}
`;

export const statesQuery = (fragment) => gql`
	${fragment}
	query States(
		$limit: Int
		$skip: Int
		$filter: FilterFindManyStateInput
		$sort: JSON
	) {
		statesV2(limit: $limit, skip: $skip, filter: $filter, sort: $sort) {
			...statesQueryFragment
		}
	}
`;

export const paginateStatesQuery = (fragment) => gql`
	${fragment}
	query PaginateStates(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyStateInput
		$sort: JSON
	) {
		paginateStatesV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateStatesQueryFragment
		}
	}
`;

export const updateStateMutation = (fragment) => gql`
	${fragment}
	mutation UpdateState(
		$record: UpdateOneStateInput!
		$filter: FilterUpdateOneStateInput
		$skip: Int
		$sort: JSON
	) {
		updateStateV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateStateMutationFragment
		}
	}
`;
