import MainService from "../Main";
import * as operations from "./Question.graphql";

const { NODE_ENV } = process.env;

class QuestionService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getQuestions(variables = {}, options = {}) {
		return this.query(variables, options, "questions");
	}

	createQuestion(variables = {}, options = {}) {
		return this.mutation(variables, options, "createQuestion");
	}

	updateQuestion(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateQuestion");
	}

	cloneQuestionByService(variables = {}, options = {}) {
		return this.mutation(variables, options, "cloneQuestionByService");
	}

	cloneQuestionByCategory(variables = {}, options = {}) {
		return this.mutation(variables, options, "cloneQuestionByCategory");
	}

	removeQuestions(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeQuestions");
	}
}

export default QuestionService;
