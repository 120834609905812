import MainService from "../Main";
import * as operations from "./Subscription.graphql.js";

class SubscriptionService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}
}

export default SubscriptionService;
