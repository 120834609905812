import gql from "graphql-tag";

export const uploadsQuery = (fragment) => gql`
	${fragment}
	query Uploads(
		$sort: JSON
		$filter: FilterFindManyUploadInput
		$limit: Int
		$skip: Int
	) {
		uploadsV2(limit: $limit, filter: $filter, skip: $skip, sort: $sort) {
			...uploadsQueryFragment
		}
	}
`;

export const uploadQuery = (fragment) => gql`
	${fragment}
	query Uploads($sort: JSON, $filter: FilterFindOneUploadInput, $skip: Int) {
		uploadV2(filter: $filter, skip: $skip, sort: $sort) {
			...uploadQueryFragment
		}
	}
`;

export const updateUploadMutation = (fragment) => gql`
	${fragment}
	mutation UpdateUpload(
		$record: UpdateOneUploadInput!
		$filter: FilterUpdateOneUploadInput
		$skip: Int
		$sort: JSON
	) {
		updateUploadV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateUploadMutationFragment
		}
	}
`;

export const updateUploadsMutation = (fragment) => gql`
	${fragment}
	mutation UpdateUploads(
		$record: UpdateManyUploadInput!
		$filter: FilterUpdateManyUploadInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		updateUploadsV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
			limit: $limit
		) {
			...updateUploadsMutationFragment
		}
	}
`;

export const removeUploadsMutation = (fragment) => gql`
	${fragment}
	mutation RemoveUploads($ids: [ID]!) {
		removeUploads(ids: $ids) {
			...removeUploadsMutationFragment
		}
	}
`;

export const createUploadMutation = (fragment) => gql`
	${fragment}
	mutation CreateUpload(
		$user_type: EnumUploadUser_type!
		$upload_type: EnumUploadType!
		$upload_type_id: String
		$original_file_name: String!
		$hash: String!
	) {
		createUpload(
			user_type: $user_type
			upload_type: $upload_type
			upload_type_id: $upload_type_id
			original_file_name: $original_file_name
			hash: $hash
		) {
			...createUploadMutationFragment
		}
	}
`;

export const approveUploadsMutation = (fragment) => gql`
	${fragment}
	mutation ApproveUploads($ids: [ID]!, $auto_approved: Boolean) {
		approveUploads(ids: $ids, auto_approved: $auto_approved) {
			...approveUploadsMutationFragment
		}
	}
`;
