import gql from "graphql-tag";

export const leadByTokenQuery = (fragment) => {
	const def = fragment.definitions.find(
		(def) => def.name?.value === "leadByTokenQueryFragment"
	);

	const hasQuotes = def.selectionSet.selections.some(
		(sel) => sel.name.value === "quotes"
	);

	return hasQuotes
		? gql`
				${fragment}
				query LeadByToken(
					$filter: FilterFindOneLeadInput
					$skip: Int
					$sort: JSON
					$quotesFilter: FilterFindManyQuoteInput = {}
				) {
					leadByTokenV2(filter: $filter, skip: $skip, sort: $sort) {
						...leadByTokenQueryFragment
					}
				}
		  `
		: gql`
				${fragment}
				query LeadByToken(
					$filter: FilterFindOneLeadInput
					$skip: Int
					$sort: JSON
				) {
					leadByTokenV2(filter: $filter, skip: $skip, sort: $sort) {
						...leadByTokenQueryFragment
					}
				}
		  `;
};

export const leadsQuery = (fragment) => gql`
	${fragment}
	query LeadsV2(
		$filter: FilterFindManyLeadInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		leadsV2(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
			...leadsQueryFragment
		}
	}
`;

export const leadsByTokenQuery = (fragment) => gql`
	${fragment}
	query LeadsByToken(
		$filter: FilterFindManyLeadInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		leadsByTokenV2(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
			...leadsByTokenQueryFragment
		}
	}
`;

export const deleteLeadMutation = (fragment) => gql`
	${fragment}
	mutation DeleteLead($_id: ID!) {
		deleteLead(_id: $_id) {
			...deleteLeadMutationFragment
		}
	}
`;

export const updateJobStatusMutation = (fragment) => gql`
	${fragment}
	mutation UpdateJobStatus($input: UpdateJobStatusInput) {
		updateJobStatus(input: $input) {
			...updateJobStatusMutationFragment
		}
	}
`;

export const updateLeadByTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateLeadByToken(
		$record: UpdateOneLeadInput!
		$filter: FilterUpdateOneLeadInput
		$skip: Int
		$sort: JSON
	) {
		updateLeadByTokenV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateLeadByTokenMutationFragment
		}
	}
`;

export const matchedLeadsQuery = (fragment) => gql`
	${fragment}
	query MatchedLeads($proId: ID!) {
		matchedLeads(proId: $proId) {
			...matchedLeadsQueryFragment
		}
	}
`;

export const matchedLeadQuery = (fragment) => gql`
	${fragment}
	query MatchedLead($proId: ID!, $leadKey: ID!) {
		matchedLead(proId: $proId, leadKey: $leadKey) {
			...matchedLeadQueryFragment
		}
	}
`;

export const paginateLeadsQuery = (fragment) => gql`
	${fragment}
	query PaginateLeadsV2(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyLeadInput
		$sort: JSON
	) {
		paginateLeadsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateLeadsQueryFragment
		}
	}
`;

export const countLeadsByTokenQuery = (fragment) => gql`
	${fragment}
	query CountLeadsByToken($filter: FilterCountLeadInput) {
		countLeadsByTokenV2(filter: $filter)
	}
`;

export const findOpenLeadQuery = (fragment) => {
	const def = fragment.definitions.find(
		(def) => def.name?.value === "findOpenLeadQueryFragment"
	);

	const hasQuotes = def.selectionSet.selections.some(
		(sel) => sel.name.value === "quotes"
	);

	return hasQuotes
		? gql`
				${fragment}
				query FindOpenLead(
					$serviceKey: ID!
					$zipcode: String!
					$quotesFilter: FilterFindManyQuoteInput = {}
				) {
					findOpenLead(serviceKey: $serviceKey, zipcode: $zipcode) {
						...findOpenLeadQueryFragment
					}
				}
		  `
		: gql`
				${fragment}
				query FindOpenLead($serviceKey: ID!, $zipcode: String!) {
					findOpenLead(serviceKey: $serviceKey, zipcode: $zipcode) {
						...findOpenLeadQueryFragment
					}
				}
		  `;
};

export const addLeadPicksMutation = (fragment) => gql`
	${fragment}
	mutation AddLeadPicks($leadKey: ID!, $providers: [ID]!) {
		addLeadPicks(leadKey: $leadKey, providers: $providers) {
			...addLeadPicksMutationFragment
		}
	}
`;

export const findPartnerOpportunityQuery = (fragment) => gql`
	${fragment}
	query FindPartnerOpportunity($leadKey: ID!) {
		findPartnerOpportunity(leadKey: $leadKey) {
			...findPartnerOpportunityQueryFragment
		}
	}
`;
