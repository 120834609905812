import apolloClient from "@framework/apollo/client";
import MainService from "../Main";
import * as operations from "./Answer.graphql";
import { isBrowser } from "@utils/Browser";

let client;
if (isBrowser) {
	client = apolloClient({
		ssr: false,
		window,
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
			mutate: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

const { NODE_ENV } = process.env;

class AnswerService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getAnswers(variables = {}, options = {}) {
		return this.query(variables, options, "answers");
	}

	createAnswer(variables = {}, options = {}) {
		return this.mutation(variables, options, "createAnswer");
	}

	removeAnswer(variables = {}, options = {}) {
		return this.mutation(variables, options, "removeAnswer");
	}

	createAnswers(variables = {}, options = {}, apolloClient = client) {
		const { fetchPolicy, returnMutation, batchOptions, validations } = options;
		const { createAnswersMutation: mutation } = this.mutations;
		const { createAnswersValidation: validation } =
			validations || this.validations;

		if (!mutation) throw new Error(`Must have createAnswersMutation`);
		if (returnMutation) return { mutation, validation };
		if (validation)
			try {
				this.validate(mutation, variables, validation);
			} catch (err) {
				if (NODE_ENV !== "production") console.error(err);
				return Promise.reject(err);
			}

		return (async () => {
			const batchesOf = (batchOptions || {}).batchesOf || 10;
			const inputName = (batchOptions || {}).inputName || "input";

			if (variables[inputName].length <= batchesOf) {
				const { data } = await apolloClient
					.mutate({
						mutation,
						variables,
						fetchPolicy,
					})
					.catch((err) => {
						throw err;
					});

				return data.createAnswers;
			}

			const batches = [];
			while (variables[inputName].length)
				batches.push(variables[inputName].splice(0, batchesOf));

			let answers = [];
			for (let i = 0; i < batches.length; i++) {
				const { data } = await apolloClient
					.mutate({
						mutation,
						variables: { [`${inputName}`]: batches[i] },
						fetchPolicy,
					})
					.catch((err) => {
						throw err;
					});
				if (batches.length - 1 === i) answers = [...data.createAnswers];
			}

			return answers;
		})();
	}

	updateAnswer(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateAnswer");
	}

	updateAnswers(variables = {}, options = {}, apolloClient = client) {
		const { fetchPolicy, returnMutation, batchOptions, validations } = options;
		const { updateAnswersMutation: mutation } = this.mutations;
		const { updateAnswersValidation: validation } =
			validations || this.validations;

		if (!mutation) throw new Error(`Must have updateAnswersMutation`);
		if (returnMutation) return { mutation, validation };
		if (validation)
			try {
				this.validate(mutation, variables, validation);
			} catch (err) {
				if (NODE_ENV !== "production") console.error(err);
				return Promise.reject(err);
			}

		return (async () => {
			const batchesOf = (batchOptions || {}).batchesOf || 10;
			const inputName = (batchOptions || {}).inputName || "input";

			if (variables[inputName].length <= batchesOf) {
				const { data } = await apolloClient
					.mutate({
						mutation,
						variables,
						fetchPolicy,
					})
					.catch((err) => {
						throw err;
					});

				return data.updateAnswers;
			}

			const batches = [];
			while (variables[inputName].length)
				batches.push(variables[inputName].splice(0, batchesOf));

			let answers = [];
			for (let i = 0; i < batches.length; i++) {
				const { data } = await apolloClient
					.mutate({
						mutation,
						variables: { [`${inputName}`]: batches[i] },
						fetchPolicy,
					})
					.catch((err) => {
						throw err;
					});
				if (batches.length - 1 === i) answers = [...data.updateAnswers];
			}

			return answers;
		})();
	}
}

export default AnswerService;
