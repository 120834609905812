import gql from "graphql-tag";

export const createReportByTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateReportByToken($record: CreateOneReportInput!) {
		createReportByTokenV2(record: $record) {
			...createReportByTokenMutationFragment
		}
	}
`;

export const paginateReportsQuery = (fragment) => gql`
	${fragment}
	query PaginateReports(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyReportInput
		$sort: JSON
	) {
		paginateReportsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateReportsQueryFragment
		}
	}
`;

export const updateReportMutation = (fragment) => gql`
	${fragment}
	mutation UpdateReport(
		$record: UpdateOneReportInput!
		$filter: FilterUpdateOneReportInput
		$skip: Int
		$sort: JSON
	) {
		updateReportV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateReportMutationFragment
		}
	}
`;
