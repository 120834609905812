import gql from "graphql-tag";

export const paginateAffinitiesQuery = (fragment) => gql`
	${fragment}
	query PaginateAffinities(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyAffinityInput
		$sort: JSON
	) {
		paginateAffinitiesV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateAffinitiesQueryFragment
		}
	}
`;

export const updateAffinityMutation = (fragment) => gql`
	${fragment}
	mutation UpdateAffinity(
		$record: UpdateOneAffinityInput!
		$filter: FilterUpdateOneAffinityInput
		$skip: Int
		$sort: JSON
	) {
		updateAffinityV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateAffinityMutationFragment
		}
	}
`;

export const createAffinityMutation = (fragment) => gql`
	${fragment}
	mutation CreateAffinity($record: CreateOneAffinityInput!) {
		createAffinityV2(record: $record) {
			...createAffinityMutationFragment
		}
	}
`;

export const deleteAffinityMutation = (fragment) => gql`
	${fragment}
	mutation RemoveAffinity($filter: FilterRemoveOneAffinityInput, $sort: JSON) {
		removeAffinityV2(filter: $filter, sort: $sort) {
			...deleteAffinityMutationFragment
		}
	}
`;

export const affinitiesQuery = (fragment) => gql`
	${fragment}
	query Affinities(
		$filter: FilterFindManyAffinityInput
		$skip: Int
		$limit: Int
		$sort: JSON
	) {
		affinitiesV2(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
			...affinitiesQueryFragment
		}
	}
`;
