import gql from "graphql-tag";

export const isCityCategoryStatQuery = (fragment) => gql`
	${fragment}
	query IsCityCategoryStat($uri: String!) {
		isCityCategoryStat(uri: $uri) {
			...isCityCategoryStatQueryFragment
		}
	}
`;
