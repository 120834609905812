import gql from "graphql-tag";

export const stripeCheckoutSessionUrlQuery = () => gql`
	query StripeCheckoutSessionUrl(
		$subscription_id: ID!
		$success_url: String!
		$cancel_url: String!
	) {
		stripeCheckoutSessionUrl(
			subscription_id: $subscription_id
			success_url: $success_url
			cancel_url: $cancel_url
		)
	}
`;

export const stripePortalSessionUrlQuery = () => gql`
	query StripePortalSessionUrl($return_url: String!) {
		stripePortalSessionUrl(return_url: $return_url)
	}
`;
