import gql from "graphql-tag";

export const funnelsStepsConfigurationsTagsQuery = (fragment) => gql`
	${fragment}
	query FunnelsStepsConfigurationsTagsV2(
		$filter: FilterFindManyFunnelStepConfigurationTagInput
		$skip: Int
		$sort: JSON
		$limit: Int
	) {
		funnelsStepsConfigurationsTagsV2(
			filter: $filter
			skip: $skip
			sort: $sort
			limit: $limit
		) {
			...funnelsStepsConfigurationsTagsQueryFragment
		}
	}
`;

export const createFunnelStepConfigurationTagMutation = (fragment) => gql`
	${fragment}
	mutation CreateFunnelStepConfigurationTag(
		$record: CreateOneFunnelStepConfigurationTagInput!
	) {
		createFunnelStepConfigurationTagV2(record: $record) {
			...createFunnelStepConfigurationTagMutationFragment
		}
	}
`;

export const updateFunnelStepConfigurationTagMutation = (fragment) => gql`
	${fragment}
	mutation UpdateFunnelStepConfigurationTag(
		$record: UpdateOneFunnelStepConfigurationTagInput!
		$filter: FilterUpdateOneFunnelStepConfigurationTagInput
		$skip: Int
		$sort: JSON
	) {
		updateFunnelStepConfigurationTagV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateFunnelStepConfigurationTagMutationFragment
		}
	}
`;

export const removeFunnelStepConfigurationTagMutation = (fragment) => gql`
	${fragment}
	mutation RemoveFunnelStepConfigurationTagV2(
		$filter: FilterRemoveOneFunnelStepConfigurationTagInput
		$sort: JSON
	) {
		removeFunnelStepConfigurationTagV2(filter: $filter, sort: $sort) {
			...removeFunnelStepConfigurationTagMutationFragment
		}
	}
`;
