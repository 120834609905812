import gql from "graphql-tag";

export const questionPricingQuery = (fragment) => gql`
	${fragment}
	query QuestionPricing(
		$filter: FilterFindManyQuestionPricingInput
		$sort: JSON
		$limit: Int
		$skip: Int
	) {
		questionPricingV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...questionPricingQueryFragment
		}
	}
`;

export const updateQuestionPricingMutation = (fragment) => gql`
	${fragment}
	mutation UpdateQuestionPricing(
		$record: UpdateOneQuestionPricingInput!
		$filter: FilterUpdateOneQuestionPricingInput
		$skip: Int
		$sort: JSON
	) {
		updateQuestionPricingV2(
			record: $record
			filter: $filter
			skip: $skip
			sort: $sort
		) {
			...updateQuestionPricingMutationFragment
		}
	}
`;

export const createQuestionPricingMutation = (fragment) => gql`
	${fragment}
	mutation CreateQuestionPricing($records: [CreateManyQuestionPricingInput!]!) {
		createQuestionPricingV2(records: $records) {
			...createQuestionPricingMutationFragment
		}
	}
`;

export const removeQuestionPricingMutation = (fragment) => gql`
	${fragment}
	mutation RemoveQuestionPricing(
		$filter: FilterRemoveManyQuestionPricingInput!
		$limit: Int
	) {
		removeQuestionPricingV2(filter: $filter, limit: $limit) {
			...removeQuestionPricingMutationFragment
		}
	}
`;
