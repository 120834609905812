import MainService from "../Main";
import * as operations from "./ClientNotification.graphql";

class ClientNotificationService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	clientNotificationByToken(variables = {}, options = {}) {
		return this.query(variables, options, "clientNotificationByToken");
	}

	updateClientNotificationByToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateClientNotificationByToken");
	}
}

export default ClientNotificationService;
