import gql from "graphql-tag";

export const accountByTokenQuery = (fragment) => gql`
	${fragment}
	query AccountByToken {
		accountByTokenV2 {
			...accountByTokenQueryFragment
		}
	}
`;

export const updateAccountByProviderTokenMutation = (fragment) => gql`
	${fragment}
	mutation UpdateAccountByProviderToken($record: UpdateOneAccountInput!) {
		updateAccountByProviderTokenV2(record: $record) {
			...updateAccountByProviderTokenMutationFragment
		}
	}
`;

export const paginateAccountsQuery = (fragment) => gql`
	${fragment}
	query PaginateAccounts(
		$filter: FilterFindManyAccountInput
		$page: Int
		$perPage: Int
	) {
		paginateAccountsV2(filter: $filter, page: $page, perPage: $perPage) {
			...paginateAccountsQueryFragment
		}
	}
`;

export const accountQuery = (fragment) => gql`
	${fragment}
	query Account($filter: FilterFindOneAccountInput, $sort: JSON, $skip: Int) {
		accountV2(filter: $filter, sort: $sort, skip: $skip) {
			...accountQueryFragment
		}
	}
`;

export const removeAccountMutation = (fragment) => gql`
	${fragment}
	mutation RemoveAccount($filter: FilterRemoveOneAccountInput, $sort: JSON) {
		removeAccountV2(filter: $filter, sort: $sort) {
			...removeAccountMutationFragment
		}
	}
`;

export const updateAccountMutation = (fragment) => gql`
	${fragment}
	mutation UpdateAccount(
		$filter: FilterUpdateOneAccountInput
		$record: UpdateOneAccountInput!
	) {
		updateAccountV2(filter: $filter, record: $record) {
			...updateAccountMutationFragment
		}
	}
`;
