import gql from "graphql-tag";

export const providerInboxQuery = (fragment) => gql`
	${fragment}
	query ProviderInbox(
		$filterType: InboxFilterTypeEnum
		$limit: Int
		$skip: Int
	) {
		providerInboxV2(filterType: $filterType, limit: $limit, skip: $skip) {
			...providerInboxQueryFragment
		}
	}
`;

export const clientInboxQuery = (fragment) => gql`
	${fragment}
	query ClientInbox($limit: Int, $skip: Int) {
		clientInboxV2(limit: $limit, skip: $skip) {
			...clientInboxQueryFragment
		}
	}
`;
