import gql from "graphql-tag";

export const reviewsQuery = (fragment) => gql`
	${fragment}
	query Reviews(
		$filter: FilterFindManyReviewInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		reviewsV2(filter: $filter, sort: $sort, limit: $limit, skip: $skip) {
			...reviewsQueryFragment
		}
	}
`;

export const paginateReviewsQuery = (fragment) => gql`
	${fragment}
	query PaginateReviews(
		$page: Int
		$perPage: Int
		$filter: FilterFindManyReviewInput
		$sort: JSON
	) {
		paginateReviewsV2(
			page: $page
			perPage: $perPage
			filter: $filter
			sort: $sort
		) {
			...paginateReviewsQueryFragment
		}
	}
`;

export const reviewsByProviderTokenQuery = (fragment) => gql`
	${fragment}
	query ReviewsByProviderToken(
		$filter: FilterFindManyReviewInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		reviewsByProviderTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...reviewsByProviderTokenQueryFragment
		}
	}
`;

export const reviewsByClientTokenQuery = (fragment) => gql`
	${fragment}
	query ReviewsByClientToken(
		$filter: FilterFindManyReviewInput
		$limit: Int
		$skip: Int
		$sort: JSON
	) {
		reviewsByClientTokenV2(
			filter: $filter
			sort: $sort
			limit: $limit
			skip: $skip
		) {
			...reviewsByClientTokenQueryFragment
		}
	}
`;

export const createReviewByTokenMutation = (fragment) => gql`
	${fragment}
	mutation CreateReviewByToken($record: CreateOneReviewInput!) {
		createReviewByTokenV2(record: $record) {
			...createReviewByTokenMutationFragment
		}
	}
`;

export const updateReviewMutation = (fragment) => gql`
	${fragment}
	mutation UpdateReview(
		$record: UpdateOneReviewInput!
		$filter: FilterUpdateOneReviewInput
		$skip: Int
		$sort: JSON
	) {
		updateReviewV2(record: $record, filter: $filter, skip: $skip, sort: $sort) {
			...updateReviewMutationFragment
		}
	}
`;

export const countReviewsQuery = () => gql`
	query CountReviews($filter: FilterCountReviewInput) {
		countReviewsV2(filter: $filter)
	}
`;

export const removeReviewMutation = (fragment) => gql`
	${fragment}
	mutation RemoveReview($filter: FilterRemoveOneReviewInput, $sort: JSON) {
		removeReviewV2(filter: $filter, sort: $sort) {
			...removeReviewMutationFragment
		}
	}
`;

export const approveReviewMutation = (fragment) => gql`
	${fragment}
	mutation ApproveReview($_id: ID!) {
		approveReview(_id: $_id) {
			...approveReviewMutationFragment
		}
	}
`;

export const declineReviewMutation = (fragment) => gql`
	${fragment}
	mutation DeclineReview($_id: ID!) {
		declineReview(_id: $_id) {
			...declineReviewMutationFragment
		}
	}
`;
