import MainService from "../Main";
import * as operations from "./AutoComplete.graphql";

const { PUBLIC_REST_API } = process.env;

class AutoCompleteService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getMatches(variables = {}, options = {}) {
		return this.rest(
			{ method: "post", url: `${PUBLIC_REST_API}/v1/autocomplete` },
			JSON.stringify(variables),
			Object.assign(options, { withCredentials: false }),
			"getMatches"
		);
	}
}

export default AutoCompleteService;
