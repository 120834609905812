import MainService from "../Main";
import * as operations from "./Quote.graphql";

class QuoteService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	getQuotes(variables = {}, options = {}) {
		return this.query(variables, options, "quotes");
	}

	paginateQuotesByProviderToken(variables = {}, options = {}) {
		return this.query(variables, options, "paginateQuotesByProviderToken");
	}

	paginateQuotes(variables = {}, options = {}) {
		return this.query(variables, options, "paginateQuotes");
	}

	getQuoteByProviderToken(variables = {}, options = {}) {
		return this.query(variables, options, "quoteByProviderToken");
	}

	getQuoteByClientToken(variables = {}, options = {}) {
		return this.query(variables, options, "quoteByClientToken");
	}

	searchQuotes(variables = {}, options = {}) {
		return this.query(variables, options, "searchQuotes");
	}

	quotesByProviderToken(variables = {}, options = {}) {
		return this.query(variables, options, "quotesByProviderToken");
	}

	countQuotes(variables = {}, options = {}) {
		return this.query(variables, options, "countQuotes");
	}

	countQuotesByProviderToken(variables = {}, options = {}) {
		return this.query(variables, options, "countQuotesByProviderToken");
	}

	refundQuote(variables = {}, options = {}) {
		return this.mutation(variables, options, "refundQuote");
	}

	updateQuote(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateQuote");
	}

	updateQuoteByProviderToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateQuoteByProviderToken");
	}

	updateQuoteByClientToken(variables = {}, options = {}) {
		return this.mutation(variables, options, "updateQuoteByClientToken");
	}

	acceptQuote(variables = {}, options = {}) {
		return this.mutation(variables, options, "acceptQuote");
	}

	declineQuote(variables = {}, options = {}) {
		return this.mutation(variables, options, "declineQuote");
	}

	sendQuote(variables = {}, options = {}) {
		return this.mutation(variables, options, "sendQuote");
	}
}

export default QuoteService;
